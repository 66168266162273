import { useState, useEffect } from 'react';
import { firestore, storage  } from '../firebase/firebase'; // Adjust the import path according to your project structure
import { collection, addDoc, Timestamp, query, where,
   getDocs, doc, getDoc, updateDoc, arrayUnion, arrayRemove, setDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Resizer from 'react-image-file-resizer';

// /**
//  * Create a new group in Firestore
//  */

export const createGroup = async (name, defaultCurrency, users, createdById, emoji) => {
    try {
      // Create the new group
      const groupDocRef = await addDoc(collection(firestore, 'groups'), {
        name: name,
        defaultCurrency: defaultCurrency,
        users: users,
        createdBy: createdById,
        dateCreated: Timestamp.now(),
        totalSum: 0,
        emoji: emoji
      });
      const newGroupId = groupDocRef.id;
  
      // Group object to be added in users' documents
      const groupInfo = { id: newGroupId, name: name };
  
      // Iterate through all users and add the groupInfo to their list of groups
      await Promise.all(users.map(async (userId) => {
        const userDocRef = doc(firestore, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);
  
        if (userDocSnap.exists()) {
          // If the user document exists, append the new groupInfo to the user's groups array
          await updateDoc(userDocRef, {
            groups: arrayUnion(groupInfo)
          });
        } else {
          // If the user document does not exist, create it with the new groupInfo as the initial value of the groups array
          await setDoc(userDocRef, {
            groups: [groupInfo]
          });
        }
      }));
  
      return newGroupId;
    } catch (e) {
      console.error("Error adding document: ", e);
      throw e;
    }
  };

// Utility function to convert base64 data to a blob
const base64ToBlob = (base64, type = 'image/jpeg') => {
  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type });
};

export const uploadImage = async (imageFile, groupId, userId, createdDateTime) => {
  try {
      // Convert createdDateTime to a JavaScript Date object
      let dateTime;
      if (createdDateTime instanceof Date) {
          dateTime = createdDateTime;
      } else if (createdDateTime?.toDate) {
          // If createdDateTime is a Firebase Timestamp
          dateTime = createdDateTime.toDate();
      } else {
          throw new Error("Invalid createdDateTime format");
      }

      // Format the dateTime for the filename
      const dateTimeString = dateTime.toISOString().replace(/[^a-zA-Z0-9]/g, '');

      // Construct the filename using userId and the formatted dateTime
      const filename = `${userId}_${dateTimeString}`;

      // Use groupId in the folder path to organize images by group
      const imagePath = `expense-images/${groupId}/${filename}`;

      // Resize the image file before uploading
      const resizedImageFile = await new Promise((resolve, reject) => {
          Resizer.imageFileResizer(
              imageFile,
              1000, // maxWidth
              1000, // maxHeight
              'PNG', // compressFormat
              70, // quality
              0, // rotation
              (uri) => {
                  resolve(base64ToBlob(uri)); // Convert base64 URI to blob directly
              },
              'base64', // outputType
          );
      });

      // Reference to the storage location
      const imageRef = ref(storage, imagePath);

      // Upload the resized image
      const snapshot = await uploadBytes(imageRef, resizedImageFile);

      // Get the URL of the uploaded image
      const imageUrl = await getDownloadURL(snapshot.ref);

      return {
          url: imageUrl,
          name: filename, // Return the name used for the file in storage
      };
  } catch (error) {
      console.error("Error uploading image to Firebase Storage:", error);
      throw error;
  }
};

  const fetchExchangeRates = async () => {
    const ratesDocRef = doc(firestore, 'currencies', 'latest');
    const docSnap = await getDoc(ratesDocRef);
    if (docSnap.exists()) {
      let rates = docSnap.data();
      // Check if the key "ILS" exists, then change it to "NIS"
      if (rates['ILS'] !== undefined) {
        rates['NIS'] = rates['ILS'];
        delete rates['ILS']; // Remove the original "ILS" key
      }
      // Convert all rate values to float
      Object.keys(rates).forEach(key => (rates[key] = parseFloat(rates[key])));
      return rates;
    } else {
      throw new Error("No exchange rates found!");
    }
  };
  
/**
 * Add an expense to a specific group in Firestore, including an image related to the expense.
 * 
 * @param {string} groupId - The ID of the group to add the expense to.
 * @param {string} userId - The ID of the user who is adding the expense.
 * @param {string} userName - The name of the user who is adding the expense.
 * @param {string} expenseName - The name or description of the expense.
 * @param {number} amount - The amount of the expense.
 * @param {string} currency - The currency of the expense amount.
 * @param {string} comments - Any comments related to the expense.
 * @param {File} imageFile - The image file related to the expense (optional).
 * @param {string} userSelectedDate - The date chosen by the user, in a string format that can be converted to a Timestamp.
 * @param {Timestamp} createdDateTime - The date and time when the expense record is created (automatic timestamp).
 */

export const addExpenseToGroup = async (
  groupId,
  userId,
  userName,
  expenseName,
  amount,
  currency,
  comments,
  imageFile,
  userSelectedDate,
  addedById,
  addedByName,
  shares,
  createdDateTime = Timestamp.now()
) => {
  try {
    let imgLink = '';
    let imageName = '';

    if (imageFile) {
      const uploadResult = await uploadImage(imageFile, groupId, userId, createdDateTime);
      imgLink = uploadResult.url;
      imageName = uploadResult.name;
    }

    const uniqueId = `${userId}_${createdDateTime.toMillis()}`;
    const groupDocRef = doc(firestore, 'groups', groupId);
    const groupDocSnap = await getDoc(groupDocRef);
    if (!groupDocSnap.exists()) {
      console.error("Group not found");
      return;
    }

    // Currency conversion logic
    const groupDefaultCurrency = groupDocSnap.data().defaultCurrency;
    const exchangeRates = await fetchExchangeRates();
    const numericAmount = parseFloat(amount);
    const rateToDefaultCurrency = parseFloat(exchangeRates[groupDefaultCurrency]);
    const rateFromUserCurrency = parseFloat(exchangeRates[currency]);
    const convertedAmount = numericAmount * (rateToDefaultCurrency / rateFromUserCurrency);

    // Default even split logic if shares are not provided
    if (!shares || shares.length === 0) {
      const groupUsers = groupDocSnap.data().users || [];
      shares = groupUsers.map(userId => ({ userId, share: numericAmount / groupUsers.length }));
    }

    // Convert shares directly based on converted total amount
    let convertedShares = shares.map(({ userId, share }) => {
      const sharePortionOfTotal = share / numericAmount; // Calculate the portion of the total each share comprises
      const adjustedShare = sharePortionOfTotal * convertedAmount; // Apply this portion to the converted total
      return { userId, share: parseFloat(adjustedShare.toFixed(2)) };
    });

    const expenseToAdd = {
      expenseId: uniqueId,
      addedBy: { userId: addedById, userName: addedByName },
      expenseName,
      originalAmount: numericAmount,
      originalCurrency: currency,
      convertedAmount: parseFloat(convertedAmount.toFixed(2)),
      currency: groupDefaultCurrency,
      comments,
      imgLink,
      imageName,
      createdDateTime,
      userSelectedDateTime: Timestamp.fromDate(new Date(userSelectedDate)),
      userId,
      userName,
      shares: convertedShares,
    };

    await updateDoc(groupDocRef, { expenses: arrayUnion(expenseToAdd) });
    await calculateAndUpdateUserBalancesInGroup(groupId, expenseToAdd);

    const updatedGroupDocSnap = await getDoc(groupDocRef);
    if (updatedGroupDocSnap.exists()) {
      console.log('Expense added and balances updated.');
    } else {
      console.log('Failed to fetch updated balances.');
    }
  } catch (e) {
    console.error("Error adding expense to group:", e);
  }
};


export const calculateAndUpdateUserBalancesInGroup = async (groupId, expense) => {
  try {
    const groupDocRef = doc(firestore, 'groups', groupId);
    const groupDocSnap = await getDoc(groupDocRef);

    if (!groupDocSnap.exists()) {
      console.error("Group not found");
      return;
    }

    let userBalances = groupDocSnap.data().userBalances || {};
    let userDebts = groupDocSnap.data().userDebts || {};
    let userOverallSpent = groupDocSnap.data().userOverallSpent || {};
    const { userId: payingUserId, convertedAmount, shares } = expense;

    // Update overall spent for the paying user
    userOverallSpent[payingUserId] = (userOverallSpent[payingUserId] || 0) + convertedAmount;

    const totalOriginalShares = shares.reduce((sum, share) => sum + Math.abs(share.share), 0);
    const shareRatio = totalOriginalShares ? (convertedAmount / totalOriginalShares) : 0;

    const adjustedShares = shares.map(({ userId, share }) => ({
      userId,
      share: parseFloat((share * shareRatio).toFixed(2))
    }));

    adjustedShares.forEach(({ userId, share }) => {
      if (userId !== payingUserId) {
        // Update user balances
        if (!userBalances[payingUserId]) userBalances[payingUserId] = 0;
        if (!userBalances[userId]) userBalances[userId] = 0;

        userBalances[payingUserId] += share;
        userBalances[userId] -= share;

        // Update user debts
        if (!userDebts[userId]) userDebts[userId] = {};
        if (!userDebts[payingUserId]) userDebts[payingUserId] = {};

        userDebts[userId][payingUserId] = (userDebts[userId][payingUserId] || 0) + share;

        // Adjust the debt in reverse for settlements
        let remainingDebt = userDebts[userId][payingUserId];
        let reverseDebt = userDebts[payingUserId][userId] || 0;

        if (reverseDebt > 0) {
          const settlementAmount = Math.min(remainingDebt, reverseDebt);
          userDebts[userId][payingUserId] -= settlementAmount;
          userDebts[payingUserId][userId] -= settlementAmount;

          if (userDebts[userId][payingUserId] === 0) delete userDebts[userId][payingUserId];
          if (userDebts[payingUserId][userId] === 0) delete userDebts[payingUserId][userId];
        }
      }
    });

    // Filter out any null or undefined values from user balances and debts
    userBalances = Object.fromEntries(Object.entries(userBalances).filter(([key, value]) => value != null));
    userDebts = Object.fromEntries(Object.entries(userDebts).map(([key, value]) => [
      key,
      Object.fromEntries(Object.entries(value).filter(([innerKey, innerValue]) => innerValue != null))
    ]));

    // Update the group document with the adjusted balances and overall spent
    await updateDoc(groupDocRef, {
      userBalances,
      userDebts,
      userOverallSpent
    });

  } catch (e) {
    console.error("Error calculating and updating user balances in group with shares:", e);
    throw e;
  }
};


/**
 * Fetch all expenses for a specific group from Firestore.
 * 
 * @param {string} groupId - The ID of the group for which to fetch expenses.
 * @returns {Promise<Array>} - A promise that resolves to an array of expenses.
 */
export const fetchExpensesForGroup = async (groupId) => {
  try {
      const groupDocRef = doc(firestore, 'groups', groupId);
      const groupDocSnap = await getDoc(groupDocRef);

      if (!groupDocSnap.exists()) {
          console.log("No such document!");
          return [];
      }

      const expenses = groupDocSnap.data().expenses || [];

      return expenses.map(expense => {
          return {
              userId: expense.userId,
              userName: expense.userName,
              expenseName: expense.expenseName,
              originalAmount: expense.originalAmount,
              convertedAmount: expense.convertedAmount,
              currency: expense.currency,
              originalCurrency: expense.originalCurrency,
              comments: expense.comments,
              imgLink: expense.imgLink,
              createdDateTime: expense.createdDateTime,
              userSelectedDateTime: expense.userSelectedDateTime,
              personalBalance: expense.personalBalance,
              expenseId: expense.expenseId, 
              shares:expense.shares,
              addedBy: expense.addedBy
          };
      });
  } catch (e) {
      console.error("Error fetching expenses for group:", e);
      throw e;
  }
};


/**
 * Calculate the sum of all expenses in the given date range for a group,
 * converting all expenses to the group's preferred currency.
 * 
 * @param {string} groupId The ID of the group.
 * @param {Date} startDate The start date of the range.
 * @param {Date} endDate The end date of the range.
 * @returns {Promise<number>} The sum of all expenses in the group's preferred currency.
 */
export const calculateSumOfExpensesInRange = async (groupId, startDate, endDate) => {
  try {
    // Fetch the group document from Firestore
    const groupDocRef = doc(firestore, 'groups', groupId);
    const groupDocSnap = await getDoc(groupDocRef);
    
    if (!groupDocSnap.exists()) {
      throw new Error('Group not found');
    }
    
    const groupData = groupDocSnap.data();
    const expenses = groupData.expenses || [];
    const groupDefaultCurrency = groupData.defaultCurrency;

    // Fetch the latest exchange rates
    const exchangeRates = await fetchExchangeRates();

    // Filter expenses by date range and convert to preferred currency
    const totalSum = expenses.reduce((sum, expense) => {
      const expenseDate = expense.userSelectedDateTime.toDate();
      if (expenseDate >= startDate && expenseDate <= endDate) {
        const rateToDefaultCurrency = exchangeRates[groupDefaultCurrency];
        const rateFromExpenseCurrency = exchangeRates[expense.originalCurrency];
        const convertedAmount = expense.originalAmount * (rateToDefaultCurrency / rateFromExpenseCurrency);
        
        // Log details about expenses with NaN values
        if (isNaN(expense.originalAmount) || isNaN(convertedAmount)) {
          console.log('Expense with NaN value:', expense);
        }
        return sum + parseFloat(convertedAmount); // Parse to float
      }
      return sum;
    }, 0);

    return parseFloat(totalSum.toFixed(2)); // Parse to float and round to 2 decimal places
  } catch (error) {
    console.error("Error calculating sum of expenses:", error);
    throw error;
  }
};

/**
 * fetch user's groups
 */

export const useFetchUserGroups = (userId) => {
    const [userGroups, setUserGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      if (!userId) {
        setLoading(false);
        return;
      }
  
      const fetchUserGroups = async () => {
        setLoading(true);
        try {
          // Create a query against the "groups" collection where "users" array contains the current userId
          const q = query(collection(firestore, "groups"), where("users", "array-contains", userId));
          const querySnapshot = await getDocs(q);
          const groups = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            groups.push({ id: doc.id, ...doc.data() });
          });
          setUserGroups(groups);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching user groups:", error);
          setError(error);
          setLoading(false);
        }
      };
  
      fetchUserGroups();
    }, [userId]);
  
    return { userGroups, loading, error };
  };

  export const fetchGroupUsers = async (groupId) => {
    const groupRef = doc(firestore, "groups", groupId);
    const groupSnap = await getDoc(groupRef);

    if (!groupSnap.exists()) {
        throw new Error("Group not found");
    }

    const groupData = groupSnap.data();
    // Assuming the group document has a 'users' field that is an array of user IDs
    const userPromises = groupData.users.map(userId =>
        getDoc(doc(firestore, "users", userId))
    );

    const userDocs = await Promise.all(userPromises);
    return userDocs.filter(doc => doc.exists()).map(doc => {
        const userData = doc.data();
        return {
            id: doc.id,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            currency: userData.currency,
            profilePicture: userData.profilePicture
            // Add any other user fields you need here
        };
    });
};

  export const fetchUserGroupsDirectly = async (userId) => {
    try {
      const q = query(collection(firestore, "groups"), where("users", "array-contains", userId));
      const querySnapshot = await getDocs(q);
      const groups = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return groups; // Return the groups directly
    } catch (error) {
      console.error("Error fetching user groups:", error);
      throw error; // Rethrow the error for handling by the caller
    }
  };

// Date formatting utility
export function formatDate(date, format = 'mm/dd/yyyy') {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = d.getFullYear();

    if (format === 'dd/mm/yyyy') {
        return `${day}/${month}/${year}`;
    } else {
        return `${month}/${day}/${year}`;
    }
}

/**
 * Invite or add a user to a group in Firestore, storing the invitation in the 'invitations' array.
 * 
 * @param {string} groupId - The ID of the group.
 * @param {string} userEmail - The email of the user being added to the group.
 * @param {string} [invitingUserId] - The ID of the user doing the inviting (optional).
 */
export const addUserToGroup = async (groupId, userEmail, invitingUserId = null) => {
  try {
      // Query the users collection to find the user with the provided email
      const usersQuery = query(collection(firestore, 'users'), where('email', '==', userEmail));
      const usersQuerySnapshot = await getDocs(usersQuery);

      if (!usersQuerySnapshot.empty) {
          const userDoc = usersQuerySnapshot.docs[0];
          const userId = userDoc.id;

          // Get the name of the group
          const groupDocRef = doc(firestore, 'groups', groupId);
          const groupDocSnapshot = await getDoc(groupDocRef);
          if (!groupDocSnapshot.exists()) {
              console.error("Group not found with the provided ID:", groupId);
              return;
          }
          const groupName = groupDocSnapshot.data().name;

          // Prepare the invitation data
          const invitationData = {
              invitedEmail: userEmail,
              groupId: groupId,
              groupName: groupName, // Include the group's name
              status: 'pending',
              // Conditionally include the invitingUserId if it's provided
              ...(invitingUserId && { invitedBy: invitingUserId }),
          };

          // Add the invitation to the 'invitations' array in the user's document
          await updateDoc(doc(firestore, 'users', userId), {
              invitations: arrayUnion(invitationData)
          });

      } else {
          console.error("No user found with the provided email:", userEmail);
      }
  } catch (e) {
      console.error("Error inviting user to group: ", e);
      throw e;
  }
};

export const getUserInvitations = async (userId) => {
  try {
    // Reference to the user's document
    const userDocRef = doc(firestore, 'users', userId);

    // Get the user document
    const userDocSnapshot = await getDoc(userDocRef);

    if (!userDocSnapshot.exists()) {
      console.error("No user found with the provided ID:", userId);
      return []; // Return an empty array if the user document does not exist
    }

    // Extract the invitations array from the user document
    let invitations = userDocSnapshot.data().invitations || [];
    
    // Filter out invitations where the group no longer exists
    invitations = await Promise.all(invitations.map(async (invitation) => {
      const groupDocRef = doc(firestore, 'groups', invitation.groupId);
      const groupDocSnap = await getDoc(groupDocRef);
      if (!groupDocSnap.exists()) {
        // Group does not exist, remove invitation from user's document
        await updateDoc(userDocRef, {
          invitations: arrayRemove(invitation)
        });
        return null; // Filter out this invitation
      }
      return invitation; // Keep this invitation
    }));

    // Filter null values resulting from removed invitations
    invitations = invitations.filter(invitation => invitation !== null);

    return invitations;
  } catch (e) {
    console.error("Error fetching user invitations: ", e);
    throw e; // Re-throw the error to be handled by the caller
  }
};

/**
 * Approves a specific invitation for a user.
 * 
 * @param {string} userId - The ID of the user approving the invitation.
 * @param {Object} invitation - The invitation object to approve.
 */
export const approveInvitation = async (userId, invitation) => {
  const userDocRef = doc(firestore, 'users', userId);
  const groupDocRef = doc(firestore, 'groups', invitation.groupId);

  try {
    // Add the user to the group's 'users' array
    await updateDoc(groupDocRef, {
      users: arrayUnion(userId)
    });

    // Remove the invitation from the user's 'invitations' array
    await updateDoc(userDocRef, {
      invitations: arrayRemove(invitation)
    });

  } catch (e) {
    console.error("Error approving invitation: ", e);
    throw e;
  }
};

/**
 * Declines a specific invitation for a user.
 * 
 * @param {string} userId - The ID of the user declining the invitation.
 * @param {Object} invitation - The invitation object to decline.
 */
export const declineInvitation = async (userId, invitation) => {
  const userDocRef = doc(firestore, 'users', userId);

  try {
    // Remove the invitation from the user's 'invitations' array
    await updateDoc(userDocRef, {
      invitations: arrayRemove(invitation)
    });

  } catch (e) {
    console.error("Error declining invitation: ", e);
    throw e;
  }
};

/**
 * Fetches the balances of all users in a specific group.
 * 
 * @param {string} groupId - The ID of the group for which to fetch user balances.
 * @returns {Promise<Array>} - A promise that resolves to an array of objects, each representing a user's balance within the group.
 */

export const fetchUserBalances = async (groupId) => {
  try {
    const groupDocRef = doc(firestore, 'groups', groupId);
    const groupDocSnap = await getDoc(groupDocRef);

    if (!groupDocSnap.exists()) {
      console.error("No such group exists!");
      return {};
    }

    const userBalances = groupDocSnap.data().userBalances || {};
    return userBalances;
  } catch (error) {
    console.error("Error fetching user balances for group:", error);
    throw error;
  }
};

export const fetchUserNameById = async (userId) => {
  try {
      const userDocRef = doc(firestore, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          // Assuming the document contains 'firstName' and 'lastName' fields
          const firstName = userData.firstName;
          const lastName = userData.lastName;

          // Capitalize the first letter of first and last names if they exist
          const capitalize = (name) => name ? name.charAt(0).toUpperCase() + name.slice(1) : '';
          const formattedFirstName = capitalize(firstName);
          const formattedLastName = capitalize(lastName);

          // Combine the names with a space in between
          return `${formattedFirstName} ${formattedLastName}`.trim(); 
      } else {
          console.error("No user found with ID:", userId);
          return "Unknown User"; 
      }
  } catch (error) {
      console.error("Error fetching user name:", error);
      throw error;
  }
};

/**
 * Fetches the image URL from Firebase Storage using the storage path.
 * 
 * @param {string} imgLink - The specific path of the image within the "expense-images" folder in Firebase Storage.
 * @returns {Promise<string>} - A promise that resolves to the direct image URL.
 */
export const fetchImageFromStorage = async (imgLink) => {
  try {
    const storage = getStorage(); // Initialize Firebase Storage
    
    // Ensure the imgLink includes the folder path. Example imgLink value: "h9BFWLoRf0hMI5BC2yEN/someImage.jpg"
    const fullPath = `expense-images/${imgLink}`; // Form the full path to the image
    const imageRef = ref(storage, fullPath); // Create a reference to the image using the full path

    // Fetches the download URL
    const url = await getDownloadURL(imageRef);
    return url;
  } catch (error) {
    console.error("Error fetching image from Firebase Storage:", error);
    throw error; // Rethrow the error for handling by the caller
  }
};

/**
 * Fetches all friends of the given user. A friend is defined as a user who shares at least one mutual group with the current user.
 * 
 * @param {string} currentUserId The ID of the current user.
 * @returns {Promise<Array>} A promise that resolves to an array of friends' details.
 */

export const fetchFriendsOfUserWithMutualGroups = async (currentUserId) => {
  try {
    const userGroupsQuery = query(collection(firestore, 'groups'), where('users', 'array-contains', currentUserId));
    const groupSnapshot = await getDocs(userGroupsQuery);
    const groups = groupSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const userIdsSet = new Set();
    const mutualGroups = {};

    // Collect all user IDs and group them by mutual groups
    groups.forEach(group => {
      group.users.forEach(userId => {
        if (userId !== currentUserId) {
          userIdsSet.add(userId);
          if (!mutualGroups[userId]) {
            mutualGroups[userId] = [group.name]; // Initialize with the current group's name
          } else {
            mutualGroups[userId].push(group.name); // Add the current group's name to the existing list
          }
        }
      });
    });

    const friendsDetails = [];
    for (const userId of userIdsSet) {
      const userDocRef = doc(firestore, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        friendsDetails.push({
          ...userData,
          mutualGroups: mutualGroups[userId] // Add mutual groups information
        });
      }
    }

    return friendsDetails;
  } catch (error) {
    console.error("Error fetching friends and mutual groups of user:", error);
    throw error;
  }
};

export const editExpenseInGroup = async (
  groupId,
  expenseId,
  {
      userId,
      userName,
      expenseName,
      amount,
      currency,
      comments,
      imageFile,
      userSelectedDate,
      updatedById,
      updatedByName,
      shares,
  } = {}
) => {
  try {
      if (!expenseId) {
          throw new Error("Expense ID is undefined!");
      }

      const groupDocRef = doc(firestore, 'groups', groupId);
      const groupDocSnap = await getDoc(groupDocRef);

      if (!groupDocSnap.exists()) throw new Error("Group not found");

      let expenseToUpdate = groupDocSnap.data().expenses.find(expense => expense.expenseId === expenseId);

      if (!expenseToUpdate) throw new Error("Expense not found");

      let imgLink = '', imageName = '';
      if (imageFile) {
          const uploadResult = await uploadImage(imageFile, groupId, userId, Timestamp.now());
          imgLink = uploadResult.url;
          imageName = uploadResult.name;
      }

      const exchangeRates = await fetchExchangeRates();
      const groupDefaultCurrency = groupDocSnap.data().defaultCurrency;
      const rateToDefaultCurrency = parseFloat(exchangeRates[groupDefaultCurrency]);
      const rateFromUserCurrency = parseFloat(exchangeRates[currency]);

      const convertedAmount = parseFloat(amount) * (rateToDefaultCurrency / rateFromUserCurrency);

      // Default even split logic if shares are not provided
      if (!shares || shares.length === 0) {
          const groupUsers = groupDocSnap.data().users || [];
          const shareAmount = parseFloat((convertedAmount / groupUsers.length).toFixed(2));
          shares = groupUsers.map(userId => ({ userId, share: shareAmount }));
      }

      let convertedShares = [];
      if (shares.length > 0) {
          convertedShares = shares.map(({ userId, share }) => {
              const convertedShare = parseFloat(share) * (rateToDefaultCurrency / rateFromUserCurrency);
              return { userId, share: parseFloat(convertedShare.toFixed(2)) };
          });
      }

      let oldExpense = { ...expenseToUpdate };

      expenseToUpdate = {
          ...expenseToUpdate,
          userId,
          userName,
          expenseName,
          originalAmount: parseFloat(amount),
          originalCurrency: currency,
          convertedAmount: parseFloat(convertedAmount.toFixed(2)),
          currency: groupDefaultCurrency,
          comments,
          imgLink,
          imageName,
          userSelectedDateTime: Timestamp.fromDate(new Date(userSelectedDate)),
          updatedBy: { userId: updatedById, userName: updatedByName },
          shares: convertedShares,
      };

      const updatedExpenses = groupDocSnap.data().expenses.map(exp => exp.expenseId === expenseId ? expenseToUpdate : exp);
      await updateDoc(groupDocRef, { expenses: updatedExpenses });

      await adjustUserBalancesForEditedExpense(groupId, expenseToUpdate, oldExpense);

  } catch (e) {
      console.error("Error updating expense in group:", e);
  }
};

async function adjustUserBalancesForEditedExpense(groupId, newExpense, oldExpense) {
  try {
      const groupDocRef = doc(firestore, 'groups', groupId);
      const groupDocSnap = await getDoc(groupDocRef);

      if (!groupDocSnap.exists()) {
          console.error("Group not found");
          return;
      }

      let userBalances = groupDocSnap.data().userBalances || {};
      let userDebts = groupDocSnap.data().userDebts || {};
      let userOverallSpent = groupDocSnap.data().userOverallSpent || {};

      // Function to update balances and debts based on an expense
      const updateBalancesForExpense = (expense, isRemoving = false) => {
          const { userId: payingUserId, convertedAmount, shares } = expense;
          const totalShares = shares.reduce((sum, share) => sum + Math.abs(share.share), 0);
          const shareRatio = convertedAmount / totalShares;

          shares.forEach(({ userId: shareUserId, share }) => {
              const adjustedShare = share * shareRatio;

              if (payingUserId === shareUserId) return; // Skip if the user is the same

              if (!userBalances[payingUserId]) userBalances[payingUserId] = 0;
              if (!userBalances[shareUserId]) userBalances[shareUserId] = 0;

              if (isRemoving) {
                  userBalances[payingUserId] -= adjustedShare;
                  userBalances[shareUserId] += adjustedShare;

                  if (!userDebts[shareUserId]) userDebts[shareUserId] = {};
                  userDebts[shareUserId][payingUserId] = (userDebts[shareUserId][payingUserId] || 0) - adjustedShare;

                  if (userDebts[payingUserId][shareUserId]) {
                      const settlementAmount = Math.min(userDebts[payingUserId][shareUserId], userDebts[shareUserId][payingUserId]);
                      userDebts[payingUserId][shareUserId] -= settlementAmount;
                      userDebts[shareUserId][payingUserId] -= settlementAmount;

                      if (userDebts[payingUserId][shareUserId] === 0) delete userDebts[payingUserId][shareUserId];
                      if (userDebts[shareUserId][payingUserId] === 0) delete userDebts[shareUserId][payingUserId];
                  }
              } else {
                  userBalances[payingUserId] += adjustedShare;
                  userBalances[shareUserId] -= adjustedShare;

                  if (!userDebts[shareUserId]) userDebts[shareUserId] = {};
                  userDebts[shareUserId][payingUserId] = (userDebts[shareUserId][payingUserId] || 0) + adjustedShare;

                  if (userDebts[payingUserId][shareUserId]) {
                      const settlementAmount = Math.min(userDebts[payingUserId][shareUserId], userDebts[shareUserId][payingUserId]);
                      userDebts[payingUserId][shareUserId] -= settlementAmount;
                      userDebts[shareUserId][payingUserId] -= settlementAmount;

                      if (userDebts[payingUserId][shareUserId] === 0) delete userDebts[payingUserId][shareUserId];
                      if (userDebts[shareUserId][payingUserId] === 0) delete userDebts[shareUserId][payingUserId];
                  }
              }
          });

          // Update overall spent for the paying user
          userOverallSpent[payingUserId] = (userOverallSpent[payingUserId] || 0) + (isRemoving ? -convertedAmount : convertedAmount);
      };

      // Remove the old expense
      updateBalancesForExpense(oldExpense, true);

      // Add the new expense
      updateBalancesForExpense(newExpense, false);

      // Filter out any zero or near-zero balances and debts
      const TOLERANCE = 1e-10;
      userBalances = Object.fromEntries(Object.entries(userBalances).filter(([key, value]) => Math.abs(value) > TOLERANCE));
      userDebts = Object.fromEntries(Object.entries(userDebts).map(([key, value]) => [
          key,
          Object.fromEntries(Object.entries(value).filter(([innerKey, innerValue]) => Math.abs(innerValue) > TOLERANCE))
      ]).filter(([key, value]) => Object.keys(value).length > 0));

      // Update Firestore with the new balances and debts
      await updateDoc(groupDocRef, { userBalances, userDebts, userOverallSpent });

  } catch (e) {
      console.error("Error adjusting user balances for edited expense:", e);
  }
}


export const deleteExpense = async (groupId, expenseId) => {
  const groupRef = doc(firestore, "groups", groupId);
  const docSnap = await getDoc(groupRef);

  if (docSnap.exists()) {
    let expenses = docSnap.data().expenses || [];
    let userBalances = docSnap.data().userBalances || {};
    let userDebts = docSnap.data().userDebts || {};
    let userOverallSpent = docSnap.data().userOverallSpent || {};

    const expenseIndex = expenses.findIndex(expense => expense.expenseId === expenseId);

    if (expenseIndex > -1) {
      const expense = expenses[expenseIndex];
      const { shares, addedBy: { userId: payingUserId }, convertedAmount } = expense;

      // Calculate the share ratio
      const totalOriginalShares = shares.reduce((sum, share) => sum + share.share, 0);
      const shareRatio = convertedAmount / totalOriginalShares;

      // Adjust user balances and debts for each share in the expense
      shares.forEach(({ userId: shareUserId, share }) => {
        const adjustedShare = share * shareRatio;

        if (payingUserId === shareUserId) return; // Skip if the user is the same

        if (!userBalances[payingUserId]) userBalances[payingUserId] = 0;
        if (!userBalances[shareUserId]) userBalances[shareUserId] = 0;

        userBalances[payingUserId] -= adjustedShare;
        userBalances[shareUserId] += adjustedShare;

        if (!userDebts[shareUserId]) userDebts[shareUserId] = {};
        if (!userDebts[payingUserId]) userDebts[payingUserId] = {};

        userDebts[shareUserId][payingUserId] = (userDebts[shareUserId][payingUserId] || 0) - adjustedShare;

        // Handle potential reverse debts
        if (userDebts[payingUserId][shareUserId]) {
          const settlementAmount = Math.min(userDebts[payingUserId][shareUserId], userDebts[shareUserId][payingUserId]);
          userDebts[payingUserId][shareUserId] -= settlementAmount;
          userDebts[shareUserId][payingUserId] -= settlementAmount;

          if (userDebts[payingUserId][shareUserId] === 0) delete userDebts[payingUserId][shareUserId];
          if (userDebts[shareUserId][payingUserId] === 0) delete userDebts[shareUserId][payingUserId];
        }
      });

      // Adjust overall spent amount for the paying user
      userOverallSpent[payingUserId] = (userOverallSpent[payingUserId] || 0) - convertedAmount;
      if (userOverallSpent[payingUserId] === 0) {
        delete userOverallSpent[payingUserId];
      }

      // Remove the expense from the expenses array
      expenses.splice(expenseIndex, 1);

      // Clean up zero balances and null values
      const TOLERANCE = 1e-10; // Tolerance for floating-point precision issues
      userBalances = Object.fromEntries(Object.entries(userBalances).filter(([key, value]) => Math.abs(value) > TOLERANCE));
      userDebts = Object.fromEntries(Object.entries(userDebts).map(([key, value]) => [
        key,
        Object.fromEntries(Object.entries(value).filter(([innerKey, innerValue]) => Math.abs(innerValue) > TOLERANCE))
      ]).filter(([key, value]) => Object.keys(value).length > 0));

      // Update Firestore with the new expenses, userBalances, userDebts, and userOverallSpent
      await updateDoc(groupRef, { expenses, userBalances, userDebts, userOverallSpent });
    }
  }
};

export const addCommentToExpense = async (firestore, groupId, expenseId, comment) => {
  const groupRef = doc(firestore, `groups/${groupId}`);
  const groupSnap = await getDoc(groupRef);

  if (groupSnap.exists()) {
      let expenses = groupSnap.data().expenses;
      const expenseIndex = expenses.findIndex(expense => expense.userId === expenseId); // Assuming `expenseId` is `userId` here
      if (expenseIndex !== -1) {
          // Assuming `comments` is intended to be an array
          const comments = expenses[expenseIndex].comments || [];
          comments.push(comment);
          expenses[expenseIndex] = { ...expenses[expenseIndex], comments };
          await updateDoc(groupRef, { expenses });
      }
  }
};

export const addImageToExpense = async (firestore, storage, groupId, expenseId, imageFile) => {
  // Upload image
  const uploadResult = await uploadImage(storage, imageFile, groupId, expenseId); // Adjust `uploadImage` as necessary
  const imageUrl = uploadResult.url;

  // Update expense with new image URL
  const groupRef = doc(firestore, `groups/${groupId}`);
  const groupSnap = await getDoc(groupRef);

  if (groupSnap.exists()) {
      let expenses = groupSnap.data().expenses;
      const expenseIndex = expenses.findIndex(expense => expense.userId === expenseId); // Assuming `expenseId` is `userId` here
      if (expenseIndex !== -1) {
          expenses[expenseIndex] = { ...expenses[expenseIndex], imgLink: imageUrl };
          await updateDoc(groupRef, { expenses });
      }
  }
};

export const calculateSettlementAmounts = async (groupId) => {
  try {
    const groupDocRef = doc(firestore, 'groups', groupId);
    const groupDocSnap = await getDoc(groupDocRef);

    if (!groupDocSnap.exists()) {
      throw new Error('Group not found');
    }

    const userDebts = groupDocSnap.data().userDebts || {};
    const settlementAmounts = {};

    // Loop through user debts to calculate total debts
    for (const [debtorId, creditors] of Object.entries(userDebts)) {
      for (const [creditorId, amount] of Object.entries(creditors)) {
        if (!settlementAmounts[debtorId]) {
          settlementAmounts[debtorId] = {};
        }
        // Multiply by 2 to cover the debt fully
        settlementAmounts[debtorId][creditorId] = amount;
      }
    }

    return settlementAmounts;
  } catch (error) {
    console.error('Error calculating settlement amounts:', error);
    throw error;
  }
};

export const addSettlementExpense = async (groupId, payerId, payerName, receiverId, receiverName, amount, currency) => {
  try {
    const groupDocRef = doc(firestore, 'groups', groupId);
    const groupDocSnap = await getDoc(groupDocRef);

    if (!groupDocSnap.exists()) {
      throw new Error('Group not found');
    }

    // Fetch necessary data from group document
    const groupData = groupDocSnap.data();
    const groupDefaultCurrency = groupData.defaultCurrency;
    const createdDateTime = Timestamp.now();
    const userSelectedDate = new Date(createdDateTime.toMillis());
    userSelectedDate.setHours(3, 0, 0, 0);
    const userSelectedDateTime = Timestamp.fromDate(userSelectedDate);

    // Handle currency conversion if needed
    const exchangeRates = await fetchExchangeRates();
    const rateToDefaultCurrency = parseFloat(exchangeRates[groupDefaultCurrency]);
    const rateFromUserCurrency = parseFloat(exchangeRates[currency]);
    const convertedAmount = amount * (rateToDefaultCurrency / rateFromUserCurrency);

    // Create the settlement expense object
    const uniqueId = `${payerId}_${receiverId}_${createdDateTime.toMillis()}`;
    const expenseName = `Settlement from ${payerName} to ${receiverName}`;
    const comments = `Settled an amount of ${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currency}`;

    const expenseToAdd = {
      expenseId: uniqueId,
      addedBy: {
        userId: payerId,
        userName: payerName,
      },
      expenseName,
      originalAmount: amount,
      originalCurrency: currency,
      convertedAmount: parseFloat(convertedAmount.toFixed(2)),
      currency: groupDefaultCurrency,
      comments,
      imgLink: '',
      imageName: '',
      createdDateTime,
      userSelectedDateTime,
      userId: payerId,
      userName: payerName,
      shares: [
        {
          userId: payerId,
          share: -parseFloat((convertedAmount / 2).toFixed(2))
        },
        {
          userId: receiverId,
          share: parseFloat((convertedAmount / 2).toFixed(2))
        }
      ],
      isSettlement: true
    };

    // Update the group document with the new settlement expense
    await updateDoc(groupDocRef, {
      expenses: arrayUnion(expenseToAdd),
    });

    // Calculate and update user balances and debts
    await calculateAndUpdateUserBalancesInGroup(groupId, expenseToAdd);

  } catch (error) {
    console.error('Error adding settlement expense:', error);
    throw error;
  }
};