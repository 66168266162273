import '../css/Time.css'

export const Time = ({ date }) => {
    return (
        <div className='Times'>
            <div className='Time_point'>
                <span>{date}</span> {/* Use the formatting function */}
            </div>
            {/* <span className='line'/> */}
        </div>
    );
};

