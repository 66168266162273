import React, { useState, useEffect } from 'react';
import { fetchUserGroupsDirectly, fetchGroupUsers } from '../Helpers/dbFunctions';

export const UserGroups = ({ currentUserId, onSelectGroup }) => {
    const [userGroups, setUserGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [groupUsers, setGroupUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetchUserGroupsDirectly(currentUserId)
            .then(groups => {
                setUserGroups(groups);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setError(err);
                setLoading(false);
            });
    }, [currentUserId]);

    const selectGroup = async (groupId) => {
        setLoading(true);
        try {
            const users = await fetchGroupUsers(groupId);
            setGroupUsers(users);
            console.log(users)
            const selected = userGroups.find(group => group.id === groupId);
            setSelectedGroup(selected);
            setLoading(false);
        } catch (err) {
            console.error(err);
            setError(err);
            setLoading(false);
        }
    };

    const moveToGroup = () => {
        // This function could navigate the user to the group's page or perform other logic
        // For now, we'll just call onSelectGroup if it's provided
        if (onSelectGroup && selectedGroup) {
            onSelectGroup(selectedGroup.id);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div>
            <h2>User Groups</h2>
            {userGroups.map(group => (
                <div key={group.id} onClick={() => selectGroup(group.id)} className='group_list_item'>
                    {group.name}
                </div>
            ))}
            {selectedGroup && (
                <>
                    <h3>Selected Group: {selectedGroup.name}</h3>
                    <button onClick={moveToGroup} className='settle-bill-btn'>Move to Group</button>
                    <div>
                        <h4>Group Members</h4>
                        {groupUsers.map(user => (
                            <div key={user.id}>{`${user.firstName} ${user.lastName}`}</div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};
