import React, { useState, useEffect } from 'react';
import { CurrencyDropdown } from './CurrencyDropdown';
import { addExpenseToGroup, fetchUserNameById, fetchGroupUsers, editExpenseInGroup } from '../Helpers/dbFunctions';
import '../css/AddExpenses.css';
import { auth } from '../firebase/firebase';
import { UilPlus, UilUpload, UilFile, UilCalender, UilShoppingBag, UilBill, UilComment } from '@iconscout/react-unicons';

export const AddExpenses = ({ groupId, onExpenseAdded, lastUsedCurrency, defaultCurrency, editingExpenseData, isOpen }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [currency, setCurrency] = useState(lastUsedCurrency || defaultCurrency);
    const [file, setFile] = useState(null);
    const [expenseData, setExpenseData] = useState({
        date: new Date().toISOString().split('T')[0],
        description: '',
        amount: '',
        comment: '',
    });
    const [validationErrors, setValidationErrors] = useState({
        selectedUserId: false,
        description: false,
        amount: false,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isUnevenSplit, setIsUnevenSplit] = useState(false);
    const [userShares, setUserShares] = useState({});
    const [personalBalance, setPersonalBalance] = useState(0);

    useEffect(() => {
        setIsModalOpen(isOpen);

        if (isOpen && editingExpenseData) {
            const formattedDate = editingExpenseData.date ? convertDateToISO(editingExpenseData.date) : '';
            setExpenseData({
                date: formattedDate,
                description: editingExpenseData.name,
                amount: editingExpenseData.originalAmount.toString(),
                comment: editingExpenseData.comment || '',
            });
            setSelectedUserId(editingExpenseData.userId);
            setCurrency(editingExpenseData.originalCurrency);

            if (editingExpenseData.shares && editingExpenseData.shares.length > 0) {
                const updatedShares = Object.fromEntries(
                    editingExpenseData.shares.map(({ userId, share }) => [userId, (share / (editingExpenseData.originalAmount / 10)).toString()])
                );
                setUserShares(updatedShares);
                setIsUnevenSplit(true);
            } else {
                setUserShares({});
                setIsUnevenSplit(false);
            }
        }
    }, [isOpen, editingExpenseData]);

    const convertDateToISO = (dateStr) => {
        const parts = dateStr.split("/");
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
    };

    useEffect(() => {
        if (isModalOpen && groupId) {
            fetchGroupUsers(groupId).then(usersList => {
                setUsers(usersList);
                const currentUser = usersList.find(user => user.id === auth.currentUser.uid);
                if (currentUser) {
                    setSelectedUserId(currentUser.id);
                }
            }).catch(error => console.error("Failed to fetch group users:", error));
        }
    }, [isModalOpen, groupId, auth.currentUser?.uid]);

    const toggleModal = () => {
        if (isModalOpen) {
            setValidationErrors({
                selectedUserId: false,
                description: false,
                amount: false,
            });
            setExpenseData({
                date: new Date().toISOString().split('T')[0],
                description: '',
                amount: '',
                comment: '',
            });
            setSelectedUserId('');
            setFile(null);
        }
        setIsModalOpen(!isModalOpen);
    };

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setFile(files[0]);
        } else {
            setExpenseData({ ...expenseData, [name]: value });
        }
    };

    const handleCurrencyChange = (selectedCurrency) => setCurrency(selectedCurrency);

    const validateForm = () => {
        const errors = {};
        errors.selectedUserId = !selectedUserId;
        errors.description = !expenseData.description.trim();
        errors.amount = !expenseData.amount.trim();
        
        if (isUnevenSplit) {
            const totalShare = Object.values(userShares).reduce((sum, share) => sum + parseFloat(share), 0);
            if (totalShare !== 10) {
                errors.userShares = true;
            }
        }

        setValidationErrors(errors);
        return !Object.values(errors).some(error => error);
    };

    const handleAddExpense = async () => {
        if (!auth.currentUser) {
            console.error("No user logged in");
            return;
        }
        setIsSubmitting(true);

        if (!validateForm()) {
            console.error("Validation failed");
            setIsSubmitting(false);
            return;
        }

        try {
            const addedById = auth.currentUser.uid;
            const addedByName = await fetchUserNameById(addedById);
            const selectedUser = users.find(user => user.id === selectedUserId);
            if (!selectedUser) {
                console.error("Selected user not found");
                return;
            }
            const expenseUserName = `${selectedUser.firstName} ${selectedUser.lastName}`;

            const shares = isUnevenSplit
                ? Object.entries(userShares).map(([userId, share]) => ({
                    userId,
                    share: (parseFloat(share) / 10) * parseFloat(expenseData.amount)
                }))
                : null;

            let totalExpenseAmount = parseFloat(expenseData.amount);
            let userPersonalShare = 0;

            if (isUnevenSplit && shares) {
                userPersonalShare = shares.find(share => share.userId === selectedUserId)?.share || 0;
                setPersonalBalance(userPersonalShare);
            }

            await addExpenseToGroup(
                groupId,
                selectedUserId,
                expenseUserName,
                expenseData.description,
                totalExpenseAmount,
                currency,
                expenseData.comment || null,
                file,
                expenseData.date,
                addedById,
                addedByName,
                shares
            );

            onExpenseAdded?.();
            setIsModalOpen(false);
            setFile(null);
            setCurrency(lastUsedCurrency || '');
            setExpenseData({
                date: new Date().toISOString().split('T')[0],
                description: '',
                amount: '',
                comment: '',
            });
        } catch (error) {
            console.error("Failed to add expense:", error);
        }
        setIsSubmitting(false);
    };

    const handleSaveExpense = async () => {
        if (!auth.currentUser) {
            console.error("No user logged in");
            return;
        }
        setIsSubmitting(true);

        if (!validateForm()) {
            console.error("Validation failed");
            setIsSubmitting(false);
            return;
        }

        try {
            const updatedById = auth.currentUser.uid;
            const updatedByName = await fetchUserNameById(updatedById);
            const selectedUser = users.find(user => user.id === selectedUserId);
            const userName = selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : '';
            const shares = isUnevenSplit
                ? Object.entries(userShares).map(([userId, share]) => ({
                    userId,
                    share: (parseFloat(share) / 10) * parseFloat(expenseData.amount)
                }))
                : null;

            let totalExpenseAmount = parseFloat(expenseData.amount);
            let userPersonalShare = 0;

            if (isUnevenSplit && shares) {
                userPersonalShare = shares.find(share => share.userId === selectedUserId)?.share || 0;
                setPersonalBalance(userPersonalShare);
            }

            const expenseUpdateData = {
                userId: selectedUserId,
                userName,
                expenseName: expenseData.description,
                amount: totalExpenseAmount,
                currency,
                comments: expenseData.comment || null,
                imageFile: file,
                userSelectedDate: expenseData.date,
                updatedById,
                updatedByName,
                shares
            };

            await editExpenseInGroup(
                groupId,
                editingExpenseData.expenseId,
                expenseUpdateData
            );

            onExpenseAdded?.();
            toggleModal();
            setIsModalOpen(false);
            setFile(null);
            setCurrency(lastUsedCurrency || defaultCurrency);
            setExpenseData({
                date: new Date().toISOString().split('T')[0],
                description: '',
                amount: '',
                comment: '',
            });
            setSelectedUserId('');
        } catch (error) {
            console.error("Failed to save expense:", error);
        }
        setIsSubmitting(false);
    };

    const handleShareChange = (userId, value) => {
        const parsedValue = parseFloat(value);

        if (!isNaN(parsedValue)) {
            const otherSharesTotal = Object.entries(userShares)
                .filter(([id]) => id !== userId)
                .reduce((sum, [_, share]) => sum + parseFloat(share || 0), 0);

            const remainingShare = Math.max(10 - otherSharesTotal, 0);

            const adjustedShare = Math.min(parsedValue, remainingShare);

            setUserShares((prevShares) => ({
                ...prevShares,
                [userId]: adjustedShare.toString()
            }));
        }
    };

    const shareOptions = Array.from({ length: 10 }, (_, i) => i + 1);

    return (
        <div className='AddExpenses'>
            <button onClick={toggleModal} className='add_one_exp'>
                <UilPlus className='plus' />
            </button>
            {isModalOpen && (
                <div>
                    <div className="modal-overlay"></div>
                    <div className="modal">
                        <span className="close-btn" onClick={toggleModal}>&times;</span>

                        <div className='expense_owner'>
                            <span>
                                Who Paid?
                            </span>
                            <select
                                onChange={(e) => setSelectedUserId(e.target.value)}
                                className='add_expense_dropdown'
                                value={selectedUserId}
                            >
                                <option value="">Select User</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstName} {user.lastName}</option>
                                ))}
                            </select>
                        </div>

                        <div className='modal_to_left'>
                            <UilCalender size={18} className="input-icon" />
                            <input
                                type="date"
                                name="date"
                                placeholder="Date"
                                value={expenseData.date}
                                onChange={handleInputChange}
                                className='date_input'
                            />
                        </div>

                        <div className='modal_to_left'>
                            <UilShoppingBag size={18} className="input-icon" />
                            <input
                                type="text"
                                name="description"
                                placeholder="Paid for ?"
                                value={expenseData.description}
                                onChange={handleInputChange}
                                className={`expense_name_input ${validationErrors.description ? 'error' : ''}`}
                            />
                        </div>

                        <div className='modal_to_left'>
                            <UilBill size={18} className="input-icon" />
                            <div className='amount_and_currency'>
                                <input
                                    type="number"
                                    name="amount"
                                    placeholder="0.00"
                                    inputMode="decimal" pattern="[0-9]*"
                                    value={expenseData.amount}
                                    onChange={handleInputChange}
                                    className={`expense_amount_input ${validationErrors.amount ? 'error' : ''}`}
                                />
                                <CurrencyDropdown
                                    className='add_expense_dropdown'
                                    onChange={handleCurrencyChange}
                                    defaultValue={currency}
                                    lastUsedCurrency={lastUsedCurrency}
                                    displayType='code'
                                />
                            </div>
                        </div>

                        <div className='modal_to_left'>
                            <div className="uneven-split-option">
                                <span>Uneven Split</span>
                                <label className="toggle-switch">
                                    <input
                                        type="checkbox"
                                        checked={isUnevenSplit}
                                        onChange={(e) => setIsUnevenSplit(e.target.checked)}
                                    />
                                    <span className="switch-slider"></span>
                                </label>
                            </div>
                        </div>

                        {isUnevenSplit && (
                            <div className='modal_to_left'>
                                <div className="uneven-split-details">
                                    {users.map((user) => (
                                        <div key={user.id} className="user-share-input">
                                            <label>{`${user.firstName} ${user.lastName}`}</label>
                                            <select
                                                value={userShares[user.id] || ''}
                                                onChange={(e) => handleShareChange(user.id, e.target.value)}
                                            >
                                                <option value="">Select Share</option>
                                                {shareOptions.map(option => (
                                                    <option key={option} value={option}>{`${option}/10`}</option>
                                                ))}
                                            </select>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {validationErrors.userShares && (
                            <div className="error-message">Total share must be 10</div>
                        )}

                        {/* <div className='modal_to_left'>
                            <UilComment size={18} className="input-icon" />
                            <input
                                type="text"
                                name="comment"
                                placeholder="Add a Note"
                                value={expenseData.comment}
                                onChange={handleInputChange}
                                className='expense_comment_input'
                            />
                        </div> */}

                        <label htmlFor="file_img_upload" className="file-upload-label">
                            <div className="upload-icon">
                                {file ? <UilFile size={18} /> : <UilUpload size={18} />}
                            </div>
                            <input
                                id="file_img_upload"
                                type="file"
                                name="image"
                                accept="image/*"
                                onChange={handleInputChange}
                                className="file_img_upload"
                            />
                            Choose image
                        </label>
                        {file && <div id="file-name-display">{file.name}</div>}

                        <button onClick={editingExpenseData ? handleSaveExpense : handleAddExpense} className='create_group_btn add_btn' disabled={isSubmitting}>
                            {editingExpenseData ? 'Save' : 'Add'}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
