import React, { useState, useEffect } from 'react';
import { addUserToGroup } from '../Helpers/dbFunctions'; // Adjust import path as necessary

export const AddPartnertoGroup = ({ userId, userGroups, closeModal }) => {
    const [userEmail, setUserEmail] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [invitationLink, setInvitationLink] = useState('');
    const [copyButtonText, setCopyButtonText] = useState('Copy group link');
    const [invitationSent, setInvitationSent] = useState(false);
    const [error, setError] = useState(false);
    const [invitationMethod, setInvitationMethod] = useState('link');

    useEffect(() => {
        if (selectedGroup) {
            const group = userGroups.find(group => group.id === selectedGroup);
            if (group) {
                // Trim spaces in the group name
                const trimmedGroupName = group.name.replace(/\s+/g, '+');
                const link = `/MyDineroz/invitation/${trimmedGroupName}/${selectedGroup}`;
                setInvitationLink(link);
                setCopyButtonText('Copy Link');
            }
        }
    }, [selectedGroup, userGroups]);

    const handleEmailChange = (e) => {
        setUserEmail(e.target.value);
    };

    const handleGroupChange = (e) => {
        setSelectedGroup(e.target.value);
    };

    const handleMethodChange = (e) => {
        setInvitationMethod(e.target.value);
    };

    const sendInvitation = async () => {
        if (invitationMethod === 'email') {
            if (!userEmail || !selectedGroup || !userId) {
                console.log("Missing information for email invitation: userEmail, selectedGroup, or userId is undefined.");
                setError(true);
                return;
            }

            try {
                await addUserToGroup(selectedGroup, userEmail, userId);
                console.log("Invitation sent successfully via email!");
            } catch (error) {
                console.error("Error sending invitation via email:", error);
            }
        } else {
            console.log("Invitation link generated:", invitationLink);
        }

        setError(false);
        setInvitationSent(true);
    };

    const copyToClipboard = () => {
        if (!selectedGroup) {
            console.error("Please select a group first.");
            setError(true);
            return;
        }
        navigator.clipboard.writeText('Here is a link to join my Dineroz group!'+ ' ' + window.location.origin + invitationLink)
            .then(() => {
                console.log("Invitation link copied to clipboard.");
                setCopyButtonText('Copied'); // Change button text to "Copied"
                setError(false);
            })
            .catch((err) => {
                console.error("Failed to copy the link to clipboard:", err);
                setError(true);
            });
    };

    return (
        <div>
            <div className="modal-overlay"></div>
            <div className="modal">
                <div className="modal-content">
                    <span className="close-btn" onClick={closeModal}>&times;</span>

                    {/* <div>
                        <select onChange={handleMethodChange}
                        value={invitationMethod}    
                        className='add_expense_dropdown' style={{width:'unset'}}>
                            <option value="link">Send an invite Link</option>
                            <option value="email">Pick user by Email</option>
                        </select>
                    </div> */}
                    
                    <select
                        className='add_expense_dropdown'
                        style={{width: "80%"}}
                        value={selectedGroup}
                        onChange={handleGroupChange}>
                        <option value="">Select Group</option>
                        {userGroups.map(group => (
                            <option key={group.id} value={group.id}>{`${group.emoji}  ${group.name}`}</option>
                        ))}
                    </select>
                    
                    {invitationMethod === 'email' && (
                        <input
                            type="email"
                            placeholder="Invited User Email"
                            value={userEmail}
                            onChange={handleEmailChange}
                        />
                    )}
                    {invitationMethod === 'email' &&
                    <button
                        className='create_group_btn'
                        onClick={sendInvitation}>
                       Send Invitation
                    </button>}

                    {invitationMethod === 'link' && (
                <button 
                    onClick={copyToClipboard} 
                    className='create_group_btn'
                    style={{backgroundColor: copyButtonText === 'Copied' ? '#00796b99' : ''}} // Change background color to green when copied
                >
                    {copyButtonText}
                </button>
            )}

                    {invitationSent && <p style={{color: 'green'}}>Invitation sent!</p>}
                    {error && <span className="error-message">* Somting is missing ☹️</span>}
                    {invitationLink && invitationMethod === 'link' && (
                        <span className='invitation_link'>{window.location.origin + invitationLink}</span>
                    )}
                </div>
            </div>
        </div>
    );
};
