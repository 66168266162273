import { useState, useEffect } from 'react';
import { GroupCreationForm } from './GroupCreationForm';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';
import '../css/ExpensesTabs.css';

const Tab = ({ tab, selected, onClick }) => {
    const handleClick = () => {
        onClick(tab); 
    };

    return (
        <li className={selected ? 'chosen_tab' : ''} onClick={handleClick}>
            <span className="emoji">{tab.emoji}</span> {tab.name}
        </li>
    );
};

export const ExpensesTab = ({ tabs, onTabClick, onGroupAdded, lastTab }) => {
    const defaultTab = {id: 'newGroup', name: 'New Group', emoji: '➕'};
    const allTabs = [defaultTab, ...tabs];
    const [selectedTab, setSelectedTab] = useState(lastTab || defaultTab);
    const [showGroupForm, setShowGroupForm] = useState(false);
    const [showTabs, setShowTabs] = useState(false); 

    useEffect(() => {
        if (tabs.length > 0) {
            setSelectedTab(lastTab || tabs[0]);
        }
    }, [tabs, lastTab, defaultTab]);

    const handleTabClick = (tab) => {
        if (tab.id === 'newGroup') {
            setShowGroupForm(true);
        } else {
            setSelectedTab(tab);
            onTabClick && onTabClick(tab);
            setShowGroupForm(false);
        }
        setShowTabs(false);
    };

    const toggleTabsVisibility = () => {
        setShowTabs(!showTabs); // Toggle the visibility state
    };

    return (
        <div>
            {showGroupForm && (
                <GroupCreationForm onGroupAdded={group => {
                    onGroupAdded(group);
                    setShowGroupForm(false);
                }} onCancel={() => setShowGroupForm(false)} />
            )}

            <div className="tabs-control">
                {allTabs.length > 1 && ( // Since allTabs includes tabs + defaultTab, check for more than 2
                    <button onClick={toggleTabsVisibility} className='show_hide_groups'>
                        {showTabs ? 'Hide Groups' : 'Change Group'}
                    </button>
                )}

                {!showTabs && (
                <div className={allTabs.length < 2 ? "chosen_tab only_defult_tab" : "selected-tab-display chosen_tab"}
                    onClick={() => handleTabClick(selectedTab)}
                >
                    <span className="emoji">{selectedTab.emoji}</span> {selectedTab.name}
                </div>
            )}
            </div>

            {showTabs && (
                <Swiper
                    spaceBetween={0}
                    slidesPerView={'auto'}
                    scrollbar={{ hide: true }}
                    modules={[Scrollbar]}
                    slidesOffsetAfter={50}
                    className='Swiper'
                >
                    {allTabs.map((tab) => (
                        <SwiperSlide key={tab.id} className='tabs_row'>
                            <Tab
                                tab={tab}
                                selected={selectedTab.id === tab.id}
                                onClick={() => handleTabClick(tab)}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </div>
    );
};