import React, { useState, useEffect } from 'react';
import { createGroup } from '../Helpers/dbFunctions';
import { CurrencyDropdown } from './CurrencyDropdown';
import { auth } from '../firebase/firebase';
import Picker from '@emoji-mart/react'
import '../css/GroupCreationForm.css';

export const GroupCreationForm = ({ onGroupAdded, onCancel }) => {
    const [groupName, setGroupName] = useState('');
    const [defaultCurrency, setdefaultCurrency] = useState('');
    const [currentUserId, setCurrentUserId] = useState('');
    const [groupEmoji, setGroupEmoji] = useState('😀');
    const [showPicker, setShowPicker] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUserId(user.uid);
            } else {
                // Handle user not logged in or redirect
            }
        });
        return () => unsubscribe();
    }, []);

    const handleCreateGroup = async () => {
        if (!currentUserId) {
            console.error("No user ID found");
            return;
        }
    
        const initialUsersList = [currentUserId]; // Define your initial users list
    
        try {
            // Include groupEmoji in the createGroup call
            const newGroupId = await createGroup(groupName, defaultCurrency, initialUsersList, currentUserId, groupEmoji);
            console.log("New group created with ID: ", newGroupId);
            // Check if onGroupAdded is a function before calling it
            if (typeof onGroupAdded === 'function') {
                onGroupAdded({id: newGroupId, name: groupName, defaultCurrency, emoji: groupEmoji, users: initialUsersList});
            }
            onCancel(); // Close the modal after successful group creation
        } catch (error) {
            console.error("Failed to create group: ", error);
        }
    };
    
    const handleCurrencyChange = (selectedCurrency) => {
        setdefaultCurrency(selectedCurrency); // Update currency state when selection changes
    };

    const addEmoji = (emoji) => {
        setGroupEmoji(emoji.native);
        setShowPicker(false)
    };     


    return (
        <div>
         <div className="modal-overlay"></div>
        <div className="modal">
            <div className="modal-content">
                <span className="close-btn" onClick={onCancel}>&times;</span>
                <h3>Create a New Group</h3>

                <div className='name_and_emoji'>

                    {/* <span>{groupEmoji}</span> */}
                    
                    <button
                    className='emoji_picker_btn'
                    onClick={() => setShowPicker(!showPicker)}>{groupEmoji}</button>
                    <input
                        type="text"
                        value={groupName}
                        onChange={(e) => {
                            // Replace '+' with an empty string to remove it from the input
                            const updatedGroupName = e.target.value.replace(/\+/g, '');
                            setGroupName(updatedGroupName);
                        }}
                        placeholder="Group Name"
                        />
                </div>
                
                {showPicker && <Picker 
                previewPosition="none"
                searchPosition="none"
                skinTonePosition="none"
                perLine="6"
                emojiButtonSize="46"
                // dynamicWidth={true}
                onEmojiSelect={addEmoji} />}

                <span className='currency_explain'>
                Chose the currency that will be used to calculate the balance of the group
                </span>

             <div className='name_and_emoji'>

                <CurrencyDropdown
                className='add_expense_dropdown group_drop'
                onChange={handleCurrencyChange}
                defaultValue={defaultCurrency}
                />
            </div>

                {/* <span>what kind of group</span>
            <div className='name_and_emoji'>
                <button>type 1</button>
                <button>type 2</button>
                <button>type 3</button>
            </div> */}

                <button onClick={handleCreateGroup} className='create_group_btn'>Create Group</button>
            </div>
        </div>
    </div>
    );
};
