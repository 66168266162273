import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { CurrencyDropdown } from './CurrencyDropdown';
import { useNavigate } from "react-router-dom";
import '../css/Signupflow.css'

export const Signup = () => {
  const [phase, setPhase] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [currency, setCurrency] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  const [keepGoogleImage, setKeepGoogleImage] = useState(true);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [profilePictureError, setProfilePictureError] = useState('');
  
  const navigate = useNavigate(); // Initialize navigate hook
  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();
  const user = auth.currentUser;
  const userEmail = user ? user.email : null;
  const userId = user ? user.uid : null;

  useEffect(() => {
    // Set the Google profile picture as the default
    if (user && user.photoURL) {
      setProfilePictureUrl(user.photoURL);
    }
  }, [user]);

  const handleNextPhase = () => {
    // Reset error states
    setFirstNameError('');
    setLastNameError('');
    setProfilePictureError('');
  
    if (phase === 1) {
      let valid = true;
      if (!firstName.trim()) {
        setFirstNameError('Please tell us your first name');
        valid = false;
      }
      if (!lastName.trim()) {
        setLastNameError("Don't forget your last name");
        valid = false;
      }
      if (valid) setPhase(2); // Only proceed if both fields are valid
    } else if (phase === 2) {
      // Proceed to next phase without additional validation here
      setPhase(3);
    }
  };
  

  const handlePreviousPhase = () => {
    setPhase(phase - 1);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleCurrencyChange = (selectedCurrency) => {
    setCurrency(selectedCurrency);
  };

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePictureError(''); // Reset the error message upon selecting a file
      const newProfilePictureUrl = await uploadProfilePicture(file);
      setProfilePictureUrl(newProfilePictureUrl);
    } else {
      setProfilePictureError('Please upload a profile picture'); // Set error if no file is selected
    }
  };
  

  const uploadProfilePicture = async (file) => {
    const storageRef = ref(storage, `profilePictures/${userId}`);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const handleSubmit = async () => {
    if (!userId) {
      console.error('No user ID found, user must be logged in');
      return;
    }
  
    if (phase === 3 && !keepGoogleImage && !profilePictureUrl) {
      setProfilePictureError('Please upload a profile picture');
      return;
    }
  
    // Proceed with submission if validation passes
    try {
        const userDocRef = doc(db, 'users', userId);
        
        await setDoc(userDocRef, {
            userId: userId,
            firstName: firstName,
            lastName: lastName,
            email: userEmail, // Include the user's email
            currency: currency,
            profilePicture: profilePictureUrl,
            timeCreated: Timestamp.now(),
        }, { merge: true });
        console.log('User data saved to Firestore');
        navigate('/MyDineroz');
    } catch (error) {
        console.error('Error saving user data to Firestore:', error);
    }
};

return (
  <div className="signup-container">
    {profilePictureUrl && (
      <img src={profilePictureUrl} alt="Profile" className="profile-picture" />
    )}
    <div className="signup-box">
      {phase === 1 && (
        <div>
          <h2 className="signup-title">Name</h2>
          <input className="signup-input" type="text" placeholder="First Name" value={firstName} onChange={handleFirstNameChange} />
          {firstNameError && <div className="error-message">{firstNameError}</div>}
          <input className="signup-input" type="text" placeholder="Last Name" value={lastName} onChange={handleLastNameChange} />
          {lastNameError && <div className="error-message">{lastNameError}</div>}
          <button className="signup-btn" onClick={handleNextPhase}>Next</button>
        </div>
      )}


      {phase === 2 && (
        <div>
          <h2 className="signup-title">Preferred Currency</h2>
          <CurrencyDropdown className="currency-dropdown" onChange={handleCurrencyChange} defaultValue={currency} />
          <button className="signup-btn" onClick={handlePreviousPhase}>Back</button>
          <button className="signup-btn" onClick={handleNextPhase}>Next</button>
        </div>
      )}

      {phase === 3 && (
          <div>
            <h2 className="signup-title">Profile Picture</h2>
            {profilePictureUrl && (
              <div className='new_img_or_not'>
                <div>
                  <label>
                    <input type="radio" name="profilePictureChoice" value="keep" checked={keepGoogleImage} onChange={() => setKeepGoogleImage(true)} />
                    Keep my Google profile picture
                  </label>
                </div>
                {/* <div>
                  <label>
                    <input type="radio" name="profilePictureChoice" value="change" checked={!keepGoogleImage} onChange={() => setKeepGoogleImage(false)} />
                    Upload a new profile picture
                  </label>
                </div> */}
              </div>
            )}

            {!keepGoogleImage && (
              <>
                <input className="file-input" type="file" onChange={handleProfilePictureChange} />
                {profilePictureError && <div className="error-message">{profilePictureError}</div>}
              </>
            )}

            <button className="signup-btn" onClick={handlePreviousPhase}>Back</button>
            <button className="signup-btn" onClick={handleSubmit}>Submit</button>
          </div>
      )}
    </div>
  </div>
  );
};