import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetchUserGroups, fetchGroupUsers, approveInvitation } from '../Helpers/dbFunctions'; // Import the necessary functions
import { useAuth } from '../context/AuthContext';
import '../css/JoinGroupModal.css'

export const JoinGroupModal = () => {
    const { groupId, groupName: encodedGroupName } = useParams();
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const { userGroups, loading, error } = useFetchUserGroups(currentUser?.uid);

    const [joining, setJoining] = useState(false);
    const [groupMembers, setGroupMembers] = useState([]);

     // Decode groupName to handle spaces encoded as "+" or "%20"
     const groupName = decodeURIComponent(encodedGroupName.replace(/\+/g, ' '));

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const members = await fetchGroupUsers(groupId);
                setGroupMembers(members);
            } catch (error) {
                console.error('Error fetching group members:', error);
            }
        };

        if (groupId) {
            fetchMembers();
        }
    }, [groupId]);

    const handleJoinGroup = async () => {
        setJoining(true);

        if (!currentUser) {
            console.error("No user logged in");
            // Redirect to login and pass the intended destination as state
            navigate('/login', { state: { from: `/mydineroz/invitation/${groupName}/${groupId}` } });
            return;
        }
        try {
            const isMember = userGroups.some(group => group.id === groupId);
            if (!isMember) {
                await approveInvitation(currentUser.uid, { groupId }); // Call approveInvitation directly
                console.log("Successfully joined the group");
                navigate(`/mydineroz/${groupId}`);
            } else {
                console.log("User already in group");
                navigate('/mydineroz');
            }
        } catch (error) {
            console.error("Failed to join group:", error);
            navigate('/');
        }
    };

    // Handle loading and error states
    if (loading) {
        return (
            <div className="modal">
                <div className="modal-content">
                    <h2>Join Group</h2>
                    <p>Loading...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="modal">
                <div className="modal-content">
                    <h2>Join Group</h2>
                    <p>Error: {error.message}</p>
                </div>
            </div>
        );
    }

    // Placeholder content, adjust as needed
    return (
        <div className="modal join">
            <div className="modal-content">
                <h2 className='join_g'>You are invited to join {groupName}</h2>
                <p>Friends in group</p>
                <ul className='group_members_list'>
                    {groupMembers.map(member => (
                        <li key={member.id}>{member.firstName} {member.lastName}</li>
                    ))}
                </ul>
                <p>Click below to accept the invitation</p>
                <button onClick={handleJoinGroup} disabled={joining} className='join_btn'>
                    {joining ? "Joining..." : "Join Group"}
                </button>
            </div>
        </div>
    );
};
