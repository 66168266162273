import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { ExpensesTab } from './ExpensesTabs';
import { DatePartnerRow } from './DatePartnerRow';
import { TotalExpenses } from './TotalExpenses';
import { AddExpenses } from './AddExpenses';
import { Time } from './Time';
import { OneExpense } from './OneExpense';
import { HamburgerMenu } from './HamburgerMenu'
import { LoadingAnimation } from './LoadingAnimation'
import { useFetchUserGroups, fetchExpensesForGroup, formatDate, calculateSumOfExpensesInRange } from '../Helpers/dbFunctions';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import Dineroz from '../imgs/Dineroz.png'
import '../css/Main.css'

export const Main = () => {
    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(null);
    const [expenses, setExpenses] = useState([]);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [recalculateTrigger, setRecalculateTrigger] = useState(0);
    const [dateFormat, setDateFormat] = useState('dd/mm/yyyy');
    const [timeFrame, setTimeFrame] = useState(localStorage.getItem('timeFrame') || 'this_week');
    const [customDateRange, setCustomDateRange] = useState(null);
    const [dates, setDates] = useState(null);
    const [lastUsedCurrency, setLastUsedCurrency] = useState('');
    const db = getFirestore();
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const { userGroups, loading, error } = useFetchUserGroups(currentUser?.uid);
    const [loadingMinimumTimeMet, setLoadingMinimumTimeMet] = useState(false);
    const [refreshGroupsTrigger, setRefreshGroupsTrigger] = useState(0);
    const [refreshExpenses, setRefreshExpenses] = useState(false);
    const [selectedExpenseId, setSelectedExpenseId] = useState(null);
    const [editingExpense, setEditingExpense] = useState(null);
    const [editingExpenseData, setEditingExpenseData] = useState(null);

    useEffect(() => {
        if (!currentUser) {
            navigate('/'); 
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingMinimumTimeMet(true); // Set to true after 2 seconds
        }, 2000); // 2000 milliseconds = 2 seconds

        setTabs(userGroups); // Set the tabs from userGroups

        // Load the selected tab ID from localStorage
        const storedTabId = localStorage.getItem('selectedTabId');
        if (storedTabId) {
            const tabId = JSON.parse(storedTabId);
            const foundTab = userGroups.find(tab => tab.id.toString() === tabId);
            if (foundTab) {
                setSelectedTab(foundTab);
                navigate(`/mydineroz/${foundTab.id}`);
            }
        }
        return () => clearTimeout(timer);
    }, [userGroups, navigate]);

    useEffect(() => {
        localStorage.setItem('timeFrame', timeFrame);
    }, [timeFrame]);

    useEffect(() => {
        fetchAndFilterExpenses();
    }, [selectedTab, timeFrame, customDateRange, db, refreshExpenses]);

    const handleTabClick = useCallback((tab) => {
        setSelectedTab(tab);
        localStorage.setItem('selectedTabId', JSON.stringify(tab.id));
        navigate(`/mydineroz/${tab.id}`);
    }, [navigate]);

    const handleGroupAdded = (newGroup) => {
        setTabs(currentTabs => [...currentTabs, newGroup]);
        setSelectedTab(newGroup);
        localStorage.setItem('selectedTabId', JSON.stringify(newGroup.id)); // Store the tab ID in localStorage
        fetchAndFilterExpenses(newGroup);
        navigate(`/mydineroz/${newGroup.id}`);
        setRefreshGroupsTrigger(oldTrigger => oldTrigger + 1);
    };

    const fetchAndFilterExpenses = async (selectedTabOverride = selectedTab) => {
        if (!selectedTabOverride) return;

        try {
            const fetchedExpenses = await fetchExpensesForGroup(selectedTabOverride.id);
            let start, end;
            if (timeFrame === 'custom' && customDateRange) {
                start = new Date(customDateRange.startDate);
                end = new Date(customDateRange.endDate);
            } else {
                const dates = calculateStartEndDate(timeFrame);
                start = dates.start;
                end = dates.end;
            }
            end.setHours(23, 59, 59, 999); // Ensure end of day for end date

            setDates({ start: start, end: end }); // Corrected syntax for object

            const filteredExpenses = fetchedExpenses.filter(expense => {
                const expenseDate = new Date(expense.userSelectedDateTime.seconds * 1000); // Assuming Firestore timestamp
                return expenseDate >= start && expenseDate <= end;
            });

            const lastCurrency = fetchedExpenses.length ? fetchedExpenses[fetchedExpenses.length - 1].originalCurrency : selectedTabOverride.defaultCurrency;
            setLastUsedCurrency(lastCurrency);

            const sum = await calculateSumOfExpensesInRange(selectedTabOverride.id, start, end);
            setTotalExpenses(sum);

            const expensesWithImages = await Promise.all(filteredExpenses.map(expense => augmentExpenseWithUserImage(expense)));

            setExpenses(expensesWithImages);
        } catch (error) {
            console.error("Error fetching expenses:", error);
        }
    };

    const augmentExpenseWithUserImage = async (expense) => {
        const storedImageUrl = localStorage.getItem(`userImage-${expense.userId}`);
        if (storedImageUrl) {
            return { ...expense, userImageUrl: storedImageUrl };
        } else {
            try {
                const userDocRef = doc(db, 'users', expense.userId);
                const userSnap = await getDoc(userDocRef);
                if (userSnap.exists()) {
                    const imageUrl = userSnap.data().profilePicture;
                    localStorage.setItem(`userImage-${expense.userId}`, imageUrl);
                    return { ...expense, userImageUrl: imageUrl };
                }
            } catch (error) {
                console.error(`Error fetching user image for userId ${expense.userId}:`, error);
            }
            return { ...expense, userImageUrl: '/default-avatar.png' }; // Fallback image if fetching fails
        }
    };

    const handleTimeFrameChange = (selectedTimeFrame) => {
        if (typeof selectedTimeFrame === 'object' && selectedTimeFrame.startDate && selectedTimeFrame.endDate) {
            setCustomDateRange(selectedTimeFrame);
            setTimeFrame('custom');
            console.log('Selected Date Range:', selectedTimeFrame.startDate, 'to', selectedTimeFrame.endDate);
        } else {
            setTimeFrame(selectedTimeFrame);
            setCustomDateRange(null);
            const dates = calculateStartEndDate(selectedTimeFrame);
            console.log('Selected Time Frame:', selectedTimeFrame, 'Date Range:', dates.start, 'to', dates.end);
        }
    };

    const calculateStartEndDate = (timeFrame) => {
        const now = new Date();
        const start = new Date(now);
        const end = new Date(now);
        start.setHours(0, 0, 0, 0); // Set the start of the day for the start date

        switch (timeFrame) {
            case 'today':
                end.setHours(23, 59, 59, 999); // Set the end of the day for today
                break;
            case 'this_week':
                // Calculate the start date as the previous Sunday
                start.setDate(now.getDate() - now.getDay());
                // Correctly calculate the end date as the next Saturday
                end.setDate(start.getDate() + 6);
                end.setHours(23, 59, 59, 999); // Set the end of the day for the end date
                break;
            case 'this_month':
                start.setDate(1); // First day of the current month
                end.setMonth(start.getMonth() + 1, 0); // Last day of the current month
                end.setHours(23, 59, 59, 999); // Set the end of the day for the end date
                break;
            case 'this_year':
                start.setFullYear(now.getFullYear(), 0, 1); // First day of the current year
                end.setFullYear(now.getFullYear(), 11, 31); // Last day of the current year
                end.setHours(23, 59, 59, 999); // Set the end of the day for the end date
                break;
            default:
                return { start: null, end: null };
        }

        return { start, end };
    };

    const groupExpensesByDate = (expenses) => {
        const grouped = expenses.reduce((acc, expense) => {
            const expenseDate = expense.userSelectedDateTime.toDate();
            if (!isNaN(expenseDate.getTime())) {
                const dateKey = formatDate(expenseDate, dateFormat);
                if (!acc[dateKey]) {
                    acc[dateKey] = [];
                }
                acc[dateKey].push(expense);
            }
            return acc;
        }, {});

        const sortedGroups = Object.entries(grouped).sort(([dateA], [dateB]) =>
            new Date(dateB.split('/').reverse().join('-')) - new Date(dateA.split('/').reverse().join('-'))
        );

        for (const group of sortedGroups) {
            group[1].sort((a, b) =>
                b.userSelectedDateTime.toDate() - a.userSelectedDateTime.toDate()
            );
        }

        return sortedGroups;
    };

    const handleExpenseAdded = async () => {
        await fetchAndFilterExpenses();
        setRecalculateTrigger(prev => prev + 1); // Increment the trigger to signal a recalculation
        setEditingExpense(false); // Ensure to close the modal by setting this to false
        setEditingExpenseData(null); // Reset the editing data to null
    };

    const triggerRefreshExpenses = () => {
        setRecalculateTrigger(prev => prev + 1);
        setRefreshExpenses(prev => !prev);
    };

    async function fetchGroupById(groupId) {
        const db = getFirestore(); // Assuming you've initialized Firestore elsewhere
        const groupDocRef = doc(db, "groups", groupId);
        try {
            const groupSnap = await getDoc(groupDocRef);
            if (groupSnap.exists()) {
                return { id: groupSnap.id, ...groupSnap.data() }; // Return the group object with its ID
            } else {
                console.log("No such group!");
                return null;
            }
        } catch (error) {
            console.error("Error fetching group details:", error);
            throw error;
        }
    }

    const onInvitationApproved = async (groupId) => {
        try {
            const newGroup = await fetchGroupById(groupId);
            if (newGroup) {
                // Now that you have the full group object, you can use your existing logic
                handleGroupAdded(newGroup);
                console.log('Group added successfully');
            } else {
                console.error('Failed to fetch group details for groupId:', groupId);
            }
        } catch (error) {
            console.error('Error in onInvitationApproved:', error);
        }
        setRefreshGroupsTrigger(prev => prev + 1); // Trigger any additional necessary updates
    };

    const handleExpenseSelect = (expenseId) => {
        setSelectedExpenseId(expenseId === selectedExpenseId ? null : expenseId);
    };

    const handleEditExpenseInitiation = (expenseData) => {
        setEditingExpense(true);
        setEditingExpenseData(expenseData); // Use the correct parameter name
        console.log('Initiating Edit for Expense:', expenseData); // Add logging to confirm expense data
    };

    if (loading || !loadingMinimumTimeMet) return <div><LoadingAnimation /></div>;
    if (error) return <div>Error loading {error.message}</div>;

    return (
        <div className='Main'>
            <div className='Main__header'>
                <h2 
                style={{alignItems: 'center', display: 'flex', margin: '0'}}>
                    <span><img src={Dineroz} alt='Dineroz'/></span>
                </h2>
            
                <HamburgerMenu onInvitationApproved={onInvitationApproved}/>
            </div>

            <ExpensesTab 
                tabs={tabs}
                onTabClick={handleTabClick}
                onGroupAdded={handleGroupAdded}
                lastTab={selectedTab}
            />

            <DatePartnerRow
            onTimeFrameChange={handleTimeFrameChange}
            userGroups={tabs}
            userId={currentUser?.uid}
            refreshTrigger={refreshGroupsTrigger}
            timeFrame={timeFrame}
            />

           <TotalExpenses 
                totalExpenses={totalExpenses}
                groupId={selectedTab ? selectedTab.id : ''}
                dateFormat={dateFormat}
                group={selectedTab}
                recalculateTrigger={recalculateTrigger}
                dates={dates}
                />

            {selectedTab && <AddExpenses
                //edit exp
                editingExpenseData={editingExpenseData}
                isOpen={Boolean(editingExpense)}
                onClose={() => setEditingExpense(null)}
                //new exp
                groupId={selectedTab.id}
                onExpenseAdded={handleExpenseAdded}
                dateFormat={dateFormat}
                formatDate={formatDate}
                lastUsedCurrency={lastUsedCurrency}
                defaultCurrency={selectedTab.defaultCurrency} />}

                <div className='expenses-list'>
                    {groupExpensesByDate(expenses).map(([date, expensesForDate], index) => (
                        expensesForDate.length > 0 && (
                            <React.Fragment key={index}>
                                <Time date={date} />
                                {[...expensesForDate].reverse().map((expense, idx) => {
                                    return (
                                        <OneExpense
                                            key={idx}
                                            groupId={selectedTab.id}
                                            expenseId={expense.expenseId} // Ensure expenseId is correct here
                                            imgLink={expense.imgLink}
                                            isSelected={selectedExpenseId === expense.expenseId} 
                                            onSelect={() => handleExpenseSelect(expense.expenseId)} 
                                            name={expense.expenseName}
                                            addedBy={expense.addedBy}
                                            originalAmount={expense.originalAmount}
                                            convertedAmount={expense.convertedAmount}
                                            originalCurrency={expense.originalCurrency}
                                            currency={expense.currency}
                                            user={expense.userName}
                                            date={formatDate(expense.userSelectedDateTime.toDate(), dateFormat)}
                                            userImageUrl={expense.userImageUrl}
                                            triggerRefresh={triggerRefreshExpenses}
                                            onEdit={handleEditExpenseInitiation}
                                            shares={expense.shares} 
                                        />
                                    );
                                })}
                            </React.Fragment>
                        )
                    ))}
                </div>
        </div>
    );
};
