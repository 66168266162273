import React, { useState, useEffect } from 'react';
import currencies from '../Helpers/Currency.json';

export const CurrencyDropdown = ({ className, onChange, defaultValue, displayType = 'name', lastUsedCurrency }) => {
  const [defaultCurrency, setDefaultCurrency] = useState('');

  // Sort currencies based on their relevance order
  const sortedCurrencies = [...currencies].sort((a, b) => {
    if (a.code === 'NIS') return -1; // NIS should come first
    if (b.code === 'NIS') return 1;
    // Add custom logic for relevance order if needed
    return 0;
  });

  useEffect(() => {
    const initialCurrency = defaultValue || sortedCurrencies[0].code;
    
    // Only update and call onChange if the currency is different to avoid unnecessary updates
    if (defaultCurrency !== initialCurrency) {
      setDefaultCurrency(initialCurrency);
      if (onChange) {
        onChange(initialCurrency);
      }
    }
  }, [defaultValue, sortedCurrencies, onChange, defaultCurrency]);
  

  const handleCurrencyChange = (event) => {
    const selectedCurrency = event.target.value;
    if (onChange) {
      onChange(selectedCurrency);
    }
  };

  // Determine what to display based on `displayType`
  const getDisplayValue = (currency) => {
    return displayType === 'name' ? currency.name : currency.code;
  };

  return (
    <select className={className} onChange={handleCurrencyChange} value={defaultCurrency}>
      {sortedCurrencies.map(currency => (
        <option key={currency.code} value={currency.code}>{getDisplayValue(currency)}</option>
      ))}
    </select>
  );
};
