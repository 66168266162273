import { useState, useEffect } from 'react';
import { fetchFriendsOfUserWithMutualGroups } from '../Helpers/dbFunctions';

export const Friends = ({ currentUserId }) => {
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Only fetch friends if currentUserId is defined
    if (currentUserId) {
      const fetchFriends = async () => {
        try {
          setLoading(true);
          const friendsWithMutualGroups = await fetchFriendsOfUserWithMutualGroups(currentUserId);
          setFriends(friendsWithMutualGroups);
        } catch (error) {
          console.error("Failed to fetch friends:", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchFriends();
    } else {
      setLoading(false);
    }
  }, [currentUserId]);
  
  if (loading) {
    return <div>Loading friends...</div>;
  }

  return (
    <div>
      {friends.length > 0 ? (
        <ul className='friend-list'>
          {friends.map((friend) => (
            <li key={friend.userId} className='firend-item'>
            <div className='img_name'>
              <img src={friend.profilePicture} alt={`${friend.firstName} ${friend.lastName}`} style={{ width: 50, height: 50, borderRadius: '50%' }} />
              <div>{`${friend.firstName} ${friend.lastName}`}</div>
            </div>
              <div>Mutual Groups: {friend.mutualGroups.join(', ')}</div>
            </li>
          ))}
        </ul>
      ) : (
        <div>No friends found.</div>
      )}
    </div>
  );
};

