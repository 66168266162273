import { useEffect, useState } from 'react';

export const SwipeDownRefresh = ({ onRefresh, navigate, children }) => {
  const [isPulling, setIsPulling] = useState(false); // State to manage pulling indication
  useEffect(() => {
    let startY;
    let threshold = 325; // Increase the threshold for less sensitivity

    const onTouchStart = (e) => {
      startY = e.touches[0].clientY;
    };

    const onTouchMove = (e) => {
      const touchY = e.touches[0].clientY;
      if (touchY - startY > threshold / 2) {
        // Show pulling indication halfway through the threshold
        setIsPulling(true);
      }
      if (touchY - startY > threshold) {
        // Trigger the refresh action passed as a prop
        onRefresh && onRefresh();
        setIsPulling(false); // Reset pulling state after refresh
      }
    };

    const onTouchEnd = () => {
      // Hide pulling indication when the touch ends
      setIsPulling(false);
    };

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchend', onTouchEnd);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);
    };
  }, [onRefresh, navigate]);

  return (
    <>
      {isPulling && <div style={{textAlign: 'center', padding: '10px', backgroundColor: '#f0f0f0'}}>Pull down to refresh...</div>}
      {children}
    </>
  );
};
