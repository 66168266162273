import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import '../css/Home.css';
import TripGif from '../imgs/TripGif.gif';
import logo from '../imgs/Dineroz.png'; // Adjust the path as needed
import { Helmet } from 'react-helmet';

export const Home = () => {
  const [selectedGif, setSelectedGif] = useState(TripGif); // Initial GIF
  const currentYear = new Date().getFullYear(); // Get the current year
  const buttonRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offsetTop = buttonRef.current?.offsetTop || 0;
      if (window.pageYOffset > offsetTop) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="home-container">

    <Helmet>
      <title>Effortlessly Manage Shared Expenses | Dineroz</title>
      <meta name="description" content="Dineroz makes managing shared expenses simple. Track bills and payments with friends, family, and roommates - all in one place." />
      <meta name="keywords" content="expense tracking, bill splitting, budget management, trips, events, roommates, couples, currency conversion, Dineroz" />
      <meta property="og:title" content="Effortlessly Manage Shared Expenses | Dineroz" />
      <meta property="og:description" content="Track your expenses, split bills, and manage your budgets in real-time with Dineroz. Perfect for trips, events, home, and couples." />
      <meta property="og:image" content={TripGif} />
      <meta property="og:url" content="https://dineroz.io/" />
      <link rel="preload" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap" as="style" />
    </Helmet>

      <header className="home-header">

        <img src={logo} alt="Dineroz Logo" className="logo" width={'150px'} height={'50px'}/>
        <h1>Effortlessly Manage Your Group Expenses with Dineroz</h1>
        <p>Your ultimate solution for tracking expenses effortlessly with friends, family, and roommates</p>

        <div className={`login-back ${isSticky ? 'sticky-background' : ''}`} ref={buttonRef}>
          <a href="/login" className='btn btn-primary'>Get Started</a>
        </div>

      </header>

      <main className="home-main">
        <section className="home-hero">
          <h2>Effortless Expense Tracking and Splitting</h2>
          <p>Track your expenses, split bills, and manage your budgets in real-time, all in one place</p>
          <a href="/login" className="btn btn-secondary">Sign Up Now</a>
        </section>

        <Suspense fallback={<div>Loading...</div>}>
          <img src={TripGif} alt="Effortlessly Manage Your Group Expenses with Dineroz" className="home-gif" width={'277px'} height={'600px'}/>
        </Suspense>


        <section className="home-features">
          <h2>Key Features</h2>
          <ul>
            <li>
              <h3>Effortless Expense Tracking</h3>
              <p>Track your expenses effortlessly with our intuitive interface designed for simplicity.</p>
            </li>
            <li>
              <h3>Currency Conversion</h3>
              <p>Add expenses and convert values to over 20 currencies seamlessly, perfect for international trips and events.</p>
            </li>
            <li>
              <h3>Easy Bill Splitting</h3>
              <p>Split bills with friends and family easily and quickly. Ideal for roommates, trips, and group events.</p>
            </li>
            <li>
              <h3>Perfect for Any Situation</h3>
              <p>Dineroz is perfect for managing expenses at home, during trips, for events, or as a couple.</p>
            </li>
          </ul>
        </section>
        
        <section className="home-about">
          <h2>About Dineroz</h2>
          <p>Discover Dineroz, your ultimate solution for tracking expenses effortlessly with friends during trips, events, at home, or as a couple. Simplify settling up and manage your budgets in real-time, all in one place. Join us and take the hassle out of sharing expenses.</p>
        </section>
      </main>
      <footer className="home-footer">
        <p>&copy; {currentYear} Dineroz. All rights reserved.</p>
        <p>Powered by <a href="https://linkedin.com/in/ofri-yehuda/"><b>Ofri Yehuda</b></a></p>
      </footer>
    </div>
  );
};
