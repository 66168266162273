import React from 'react';
import './css/All.css';
import { SwipeDownRefresh } from './Helpers/SwipeDownRefresh';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { AuthProvider } from './context/AuthContext';
import { Home } from './components/Home';
import { Login } from './components/Login';
import { Main } from './components/Main';
import { Signup } from './components/Signupwflow';
import { JoinGroupModal } from './components/JoinGroupModal';
// import { About } from './components/About';
// import { NotFound } from './components/NotFound';

const RefreshHandler = ({ children }) => {
  const navigate = useNavigate();
  
  const handleRefresh = () => {
    navigate(0);
  };

  return <SwipeDownRefresh onRefresh={handleRefresh} navigate={navigate}>{children}</SwipeDownRefresh>;
};

const App = () => {

    return (
      <AuthProvider> 
        <BrowserRouter>
        <RefreshHandler>
          <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/login" element={<Login />} />
                  <Route path="/mydineroz" element={<Navigate replace to="/MyDineroz/newGroup" />} />
                  <Route path="/mydineroz/:groupId" element={<Main />} />
                  <Route path="/mydineroz/invitation/:groupName/:groupId" element={<JoinGroupModal />} />
                  <Route exact path="/signup" element={<Signup />} />
                  {/* <Route path="/about" component={About} />
                  <Route path="*" component={NotFound} /> */}
          </Routes>
          </RefreshHandler>
        </BrowserRouter>
      </AuthProvider>
    );
};

export default App;
