import React, { useState, useEffect } from 'react';
import { fetchUserBalances, fetchUserNameById, calculateSettlementAmounts, fetchExpensesForGroup, addSettlementExpense } from '../Helpers/dbFunctions';
import { useAuth } from '../context/AuthContext';
import { UilAngleDown } from '@iconscout/react-unicons';
import '../css/TotalExpenses.css';

export const TotalExpenses = ({ totalExpenses, group, recalculateTrigger, dates }) => {
  const [myExpenses, setMyExpenses] = useState(0);
  const [otherUsersExpenses, setOtherUsersExpenses] = useState(0);
  const [totalGroupExpenses, setTotalGroupExpenses] = useState(0);
  const [myBalance, setMyBalance] = useState(0);
  const [personalExpenses, setPersonalExpenses] = useState(0);
  const [settlementExpenses, setSettlementExpenses] = useState(0);
  const [showBalances, setShowBalances] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const [settlementAmounts, setSettlementAmounts] = useState([]);

  useEffect(() => {
    if (group) {
      setLoading(true);

      fetchUserBalances(group.id)
        .then(balances => {
          const balancePromises = Object.entries(balances).map(async ([userId, balance]) => {
            const name = await fetchUserNameById(userId);
            return { userId, balance, name };
          });

          Promise.all(balancePromises)
            .then(formattedBalancesWithNames => {
              fetchExpensesForGroup(group.id)
                .then(expenses => {
                  const totalConvertedAmount = expenses.reduce((acc, expense) => acc + (expense.convertedAmount || 0), 0);
                  setTotalGroupExpenses(totalConvertedAmount);

                  const myPersonalExpenses = expenses
                    .filter(expense => expense.userId === currentUser.uid && !expense.isSettlement)
                    .reduce((acc, expense) => acc + (expense.personalBalance || 0), 0);
                  setPersonalExpenses(myPersonalExpenses);

                  calculateExpenses(formattedBalancesWithNames, expenses, balances);
                  setLoading(false);
                })
                .catch(err => {
                  console.error("Failed to fetch expenses:", err);
                  setError(err);
                  setLoading(false);
                });
            });
        })
        .catch((err) => {
          console.error("Failed to fetch user balances:", err);
          setError(err);
          setLoading(false);
        });
    }
  }, [group, recalculateTrigger]);

  const calculateExpenses = (balancesWithNames, expenses, firebaseBalances) => {
    if (!balancesWithNames || balancesWithNames.length === 0) return;
    if (!expenses || expenses.length === 0) return;

    const myTotalExpenses = expenses
      .filter(expense => expense.userId === currentUser.uid && !expense.isSettlement)
      .reduce((total, expense) => total + (expense.convertedAmount || 0), 0);

    const otherUsersExpensesTotal = expenses
      .filter(expense => expense.userId !== currentUser.uid && !expense.isSettlement)
      .reduce((total, expense) => total + (expense.convertedAmount || 0), 0);

    const netBalance = firebaseBalances[currentUser.uid] || 0;

    setMyExpenses(myTotalExpenses);
    setOtherUsersExpenses(otherUsersExpensesTotal);
    setMyBalance(netBalance);
  };

  const toggleBalancesVisibility = () => {
    setShowBalances(!showBalances);
  };

  const handleCalculateSettlement = async () => {
    try {
      const amounts = await calculateSettlementAmounts(group.id);
      const amountsWithNames = await Promise.all(
        Object.entries(amounts).map(async ([payerId, receivers]) => {
          const payerName = payerId === currentUser.uid ? 'You' : await fetchUserNameById(payerId);
          const receiverAmounts = await Promise.all(
            Object.entries(receivers).map(async ([receiverId, amount]) => {
              let receiverName = receiverId === currentUser.uid ? 'You' : await fetchUserNameById(receiverId);

              return { payerId, payerName, receiverId, amount, receiverName };
            })
          );
          return { payerId, payerName, receiverAmounts };
        })
      );

      setSettlementAmounts(amountsWithNames || []);
    } catch (error) {
      console.error('Error calculating settlement amounts:', error);
    }
  };

  // const handleConfirmSettlement = async () => {
  //   try {
  //     if (settlementAmounts && settlementAmounts.length > 0) {
  //       const currentUserSettlement = settlementAmounts.find(settlement => settlement.payerId === currentUser.uid);
  //       if (currentUserSettlement) {
  //         for (const receiver of currentUserSettlement.receiverAmounts) {
  //           if (receiver.amount > 0) {
  //             await addSettlementExpense(group.id, currentUser.uid, currentUser.displayName, receiver.receiverId, receiver.receiverName, receiver.amount, group.defaultCurrency);
  //           }
  //         }
  //         setSettlementAmounts([]);
  //         await fetchAndFilterExpenses(); // Refetch expenses and balances after settlement
  //       } else {
  //         console.log('No settlement amount for the current user.');
  //       }
  //     } else {
  //       console.log('Settlement amounts not calculated.');
  //     }
  //   } catch (error) {
  //     console.error('Error settling group expenses:', error);
  //   }
  // };

  const fetchAndFilterExpenses = async () => {
    if (!group) return;

    try {
      const fetchedExpenses = await fetchExpensesForGroup(group.id);
      const filteredExpenses = fetchedExpenses.filter(expense => {
        const expenseDate = new Date(expense.userSelectedDateTime.seconds * 1000);
        return expenseDate >= dates.start && expenseDate <= dates.end;
      });

      const totalConvertedAmount = filteredExpenses.reduce((acc, expense) => acc + (expense.convertedAmount || 0), 0);
      setTotalGroupExpenses(totalConvertedAmount);

      const myPersonalExpenses = filteredExpenses
        .filter(expense => expense.userId === currentUser.uid && !expense.isSettlement)
        .reduce((acc, expense) => acc + (expense.personalBalance || 0), 0);
      setPersonalExpenses(myPersonalExpenses);

      fetchUserBalances(group.id)
        .then(balances => {
          const balancePromises = Object.entries(balances).map(async ([userId, balance]) => {
            const name = await fetchUserNameById(userId);
            return { userId, balance, name };
          });

          Promise.all(balancePromises)
            .then(formattedBalancesWithNames => {
              calculateExpenses(formattedBalancesWithNames, filteredExpenses, balances);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.error("Failed to fetch user balances:", err);
          setError(err);
          setLoading(false);
        });
    } catch (error) {
      console.error("Error fetching expenses:", error);
      setError(error);
      setLoading(false);
    }
  };

  const hasGroup = Boolean(group);
  const currencySymbol = group?.defaultCurrency ?? 'DefaultSymbol';

  // Determine if the current user needs to pay
  const currentUserSettlement = settlementAmounts.find(settlement => settlement.payerId === currentUser.uid);
  const needsToPay = currentUserSettlement && currentUserSettlement.receiverAmounts.some(receiver => receiver.amount > 0);

  return (
    <div className='Total'>
      {hasGroup ? (
        totalExpenses > 0 ? (
          <>
            <div className='total_and_btn'>
              <div className='Total_amount'>
                <h2>{Math.round(totalGroupExpenses).toLocaleString()}</h2>
                <span className='defaultCurrency' style={{ fontSize: 'large' }}>{currencySymbol}</span>
              </div>
              <button onClick={toggleBalancesVisibility} className='total_details'>
                {showBalances ? <><UilAngleDown className="icon-rotate" /></> : <><UilAngleDown /></>}
              </button>
            </div>
            <div>
              {showBalances && (
                <div className='Balances'>
                  <div className='Balances_row'>
                    <h3>My Expenses:</h3>
                    <h3>
                      <span>{myExpenses % 1 === 0 ? myExpenses.toLocaleString() : myExpenses.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currencySymbol}</span>
                    </h3>
                  </div>

                  {personalExpenses > 0 &&
                  <div className='Balances_row'>
                    <h3>Personal Expenses:</h3>
                    <h3>
                      <span>{personalExpenses % 1 === 0 ? personalExpenses.toLocaleString() : personalExpenses.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currencySymbol}</span>
                    </h3>
                  </div> }

                  {settlementExpenses > 0 &&
                  <div className='Balances_row'>
                    <h3>Settlement Expenses:</h3>
                    <h3>
                      <span>{settlementExpenses % 1 === 0 ? settlementExpenses.toLocaleString() : settlementExpenses.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currencySymbol}</span>
                    </h3>
                  </div> }

                  <div className='Balances_row'>
                    <h3>Others Expenses:</h3>
                    <h3>
                      <span>{otherUsersExpenses % 1 === 0 ? otherUsersExpenses.toLocaleString() : otherUsersExpenses.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currencySymbol}</span>
                    </h3>
                  </div>

                  <div className='Balances_row'>
                    <h3>My Balance:</h3>
                    <h3>
                      <span className={myBalance >= 0 ? 'balance-positive' : 'balance-negative'}>
                        {myBalance % 1 === 0 ? myBalance.toLocaleString() : myBalance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currencySymbol}
                      </span>
                    </h3>
                  </div>

                  {settlementAmounts && settlementAmounts.length > 0 ? (
                    <div>
                      <h4>Settlement Amounts:</h4>
                      {settlementAmounts.map(({ payerId, payerName, receiverAmounts }) => (
                        <div key={payerId}>
                          {receiverAmounts.map(({ receiverId, amount, receiverName }) => (
                            <p key={receiverId}>
                              {payerName} should {amount >= 0 ? 'give' : 'receive'} {Math.abs(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currencySymbol} {amount >= 0 ? 'to' : 'from'} {receiverName}
                            </p>
                          ))}
                        </div>
                      ))}
                      {/* {needsToPay && <button className="settle-bill-btn" onClick={handleConfirmSettlement}>Confirm transfer of funds</button>} */}
                    </div>
                  ) : (
                    <button className="settle-bill-btn" onClick={handleCalculateSettlement}>Settle Bill</button>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <div className='middle_mesg'>
            <h2 className='no_g'>Add your first expense to see it here!</h2>
          </div>
        )
      ) : (
        <div className='middle_mesg'>
          <h2 className='no_g'>Create your first group by clicking the + Add a group</h2>
        </div>
      )}
    </div>
  );
};
