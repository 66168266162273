import Lottie from 'react-lottie';
import Loading from '../imgs/Loading.json'; 

export const LoadingAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
  <div>
   <Lottie options={defaultOptions} height={400} width={400} />
    <h3 style={{display:'flex', justifyContent:'center', color: 'rgb(58, 169, 220)'}}>Counting your Dineroz...</h3>
   </div>
)};

