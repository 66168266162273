import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "dineroz.firebaseapp.com",
  projectId: "dineroz",
  storageBucket: "dineroz.appspot.com",
  messagingSenderId: "206253031667",
  appId: "1:206253031667:web:b1892ce998e0d12bd47786",
  measurementId: "G-ZNB229HTTD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app)