import { useState, useEffect } from 'react';
import { AddPartnertoGroup } from './AddPartnertoGroup'; 
import { UilShareAlt  } from '@iconscout/react-unicons';

import '../css/DatePartnerRow.css';

export const DatePartnerRow = ({ onTimeFrameChange, userGroups, userId, timeFrame }) =>  {
    const [selectedOption, setSelectedOption] = useState(timeFrame);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showModal, setShowModal] = useState(false); 

    useEffect(() => {
        if (selectedOption === 'custom') {
            // Format today's date as YYYY-MM-DD
            const today = new Date().toISOString().split('T')[0];
            setStartDate(today);
            setEndDate(today);
        }
    }, [selectedOption]);
    
    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        if (value !== 'custom') {
            // For predefined options, call the callback with the selected option
            onTimeFrameChange(value);
        }
    };

    const handleCustomDateChange = () => {
        if (startDate && endDate) {
            // Call the callback with custom date range when both dates are selected
            onTimeFrameChange({ startDate, endDate });
        }
    };

    useEffect(() => {
        // Call the custom date change handler whenever the start or end date changes
        handleCustomDateChange();
    }, [startDate, endDate]);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            <div className='Date_row'>
                <select value={selectedOption} onChange={handleOptionChange} className='time_frame'>
                    <option value="today">Today</option>
                    <option value="this_week">This Week</option>
                    <option value="this_month">This Month</option>
                    <option value="this_year">This Year</option>
                    <option value="custom">Custom</option>
                </select>
                {selectedOption === 'custom' && (
                    <div className='custom_date'>
                        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                )}
                <span className='add_firend_to' onClick={openModal}>
                    <UilShareAlt   className='add_person' onClick={openModal}/> 
                    Group Invite
                </span>
            </div>
            
            {showModal && (
                <AddPartnertoGroup
                    userGroups={userGroups}
                    userId={userId}
                    closeModal={closeModal}
                />
            )}
        </div>
    );
};