import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from '../firebase/firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import google from '../imgs/google.png';
import '../css/Login.css';

export const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
console.log(location.state?.from)
    useEffect(() => {
        // Automatically redirect authenticated users
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // Redirect to the intended destination or the main page
                const redirectTo = location.state?.from || '/MyDineroz';
                navigate(redirectTo);
            }
        });
        return unsubscribe; // Cleanup subscription on component unmount
    }, [navigate, location.state]);

    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            // Check if the user exists in the database
            const userRef = doc(firestore, "users", user.uid);
            const userDoc = await getDoc(userRef);

            if (!userDoc.exists()) {
                console.log('User does not exist in the database, navigating to signup flow');
                navigate('/Signup');
            } else {
                // If user exists or after signing up, redirect to intended destination
                const redirectTo = location.state?.from || '/MyDineroz';
                navigate(redirectTo);
            }
        } catch (error) {
            console.error('Error during Google sign-in:', error);
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2 className="login-title">Start counting your Dineroz</h2>
                <button className="google-login-btn" onClick={handleGoogleLogin}>
                    <span><img src={google} alt='login with google'/></span>
                    Login with Google
                </button>
                <a href='/'><span className='login_span_home'>or Back to home page</span></a>
            </div>
        </div>
    );
}    
