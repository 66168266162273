import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { auth } from '../firebase/firebase';
import { Settings } from '../components/Settings';
import { Invitations } from './Invitations';
import { Friends } from './Friends';
import { UserGroups } from './UserGroups';
import '../css/HamburgerMenu.css'

export const HamburgerMenu = ({ onInvitationApproved }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showInvitations, setShowInvitations] = useState(false);
    const [showFriends, setShowFriends] = useState(false); 
    const [showGroups, setShowGroups] = useState(false);
    const { currentUser } = useAuth();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        if (isOpen) {
            setShowSettings(false);
            setShowInvitations(false);
            setShowFriends(false); // Reset Friends visibility as well
        }
    };

    const handleLogout = async () => {
        try {
            await auth.signOut();
        } catch (error) {
            console.error('Logout Error:', error);
        }
    };

    const toggleSettings = () => {
        setShowSettings(!showSettings);
        if (showInvitations) setShowInvitations(false);
        if (showFriends) setShowFriends(false); 
    };

    const toggleInvitations = () => {
        setShowInvitations(!showInvitations);
        if (showSettings) setShowSettings(false);
        if (showFriends) setShowFriends(false); 
    };

    const toggleFriends = () => {
        setShowFriends(!showFriends);
        if (showSettings) setShowSettings(false);
        if (showInvitations) setShowInvitations(false); 
    };

    const toggleGroups = () => {
        setShowGroups(!showGroups); // Corrected to toggle showGroups instead of showFriends
        if (showSettings) setShowSettings(false);
        if (showInvitations) setShowInvitations(false);
        if (showFriends) setShowFriends(false); // Keep this if you want to hide Friends when showing Groups
    };
    

    const getFirstName = (displayName) => {
        return displayName ? displayName.split(' ')[0] : 'there';
    };

    return (
        <div className="hamburger-menu">
            <button onClick={toggleMenu} className={`hamburger-button ${isOpen ? 'open' : ''}`}>
                <span className={`hamburger-line ${isOpen ? 'top' : ''}`}></span>
                <span className={`hamburger-line ${isOpen ? 'middle' : ''}`}></span>
                <span className={`hamburger-line ${isOpen ? 'bottom' : ''}`}></span>
            </button>

            {isOpen && (
                <div className="menu-content">
                    <ul>
                        {currentUser ? (
                            <>
                                <li className='user_greet'>
                                    <img src={currentUser.photoURL || '/default-avatar.png'} alt="User Avatar" className="user-avatar"/>
                                    <div className="greeting">Hey {getFirstName(currentUser?.displayName)}</div>
                                </li>
                                {/* <li><a href="/home">Home</a></li> */}
                                {/* <li>
                                    <button onClick={toggleSettings} className='menu_btn'>
                                        {showSettings ? '✖' : 'Settings'}
                                    </button>
                                    {showSettings && <Settings />}
                                </li> */}
                                {/* <li>
                                    <button onClick={toggleInvitations} className='menu_btn'>
                                        {showInvitations ? '✖' : 'Invitations'}
                                    </button>
                                    {showInvitations && <Invitations currentUser={currentUser} onInvitationApproved={onInvitationApproved} setIsOpen={setIsOpen} />}
                                </li> */}
                                <li>
                                    <button onClick={toggleFriends} className='menu_btn'>
                                        {showFriends ? '✖' : 'Friends'}
                                    </button>
                                    {showFriends && <Friends currentUserId={currentUser?.uid} />}
                                </li>
                                <li>
                                    <button onClick={toggleGroups} className='menu_btn'>
                                        {showGroups ? '✖' : 'Groups'}
                                    </button>
                                    {showGroups && <UserGroups currentUserId={currentUser?.uid} />}
                                </li>
                                <li><button onClick={handleLogout} className='logout menu_btn'>Logout</button></li>
                            </>
                        ) : (
                            <li><a href="/login">Login</a></li>
                        )}
                        {/* <li><a href="/about">About</a></li> */}
                    </ul>
                </div>
            )}
        </div>
    );
};
