import React, { useState, useEffect } from 'react';
import { deleteExpense, addCommentToExpense, addImageToExpense, fetchUserNameById } from '../Helpers/dbFunctions';
import { UilUserCircle, UilTrashAlt, UilComment, UilEditAlt } from '@iconscout/react-unicons';
import { useAuth } from '../context/AuthContext';
import '../css/OneExpense.css';

export const OneExpense = ({
    groupId,
    expenseId,
    name,
    originalAmount,
    convertedAmount,
    currency,
    originalCurrency,
    date,
    user,
    userImageUrl,
    imgLink,
    isSelected,
    onSelect,
    triggerRefresh,
    onEdit,
    addedBy,
    shares,
}) => {
    const [commentText, setCommentText] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [imageZoomed, setImageZoomed] = useState(false);
    const [userNames, setUserNames] = useState({});
    const { currentUser } = useAuth();

    const isCurrentUser = currentUser && addedBy && currentUser.uid === addedBy.userId;

    useEffect(() => {
        const fetchUserNames = async () => {
            const names = {};
            for (const share of shares) {
                if (!userNames[share.userId]) {
                    names[share.userId] = await fetchUserNameById(share.userId);
                }
            }
            setUserNames(prevUserNames => ({ ...prevUserNames, ...names }));
        };

        if (shares && shares.length > 0) {
            fetchUserNames();
        }
    }, [shares]);

    const handleDeleteExpense = async (e) => {
        e.stopPropagation(); // Prevent triggering onSelect when clicking delete
        await deleteExpense(groupId, expenseId);
        triggerRefresh();
    };

    const handleAddCommentToExpense = async (e) => {
        e.stopPropagation(); // Prevent triggering onSelect when adding a comment
        const comment = { text: commentText, userId: user, date: new Date().toISOString() };
        await addCommentToExpense(groupId, expenseId, comment);
        setCommentText('');
    };

    const handleAddImageToExpense = async (e) => {
        e.stopPropagation(); // Prevent triggering onSelect when adding an image
        if (imageFile) {
            await addImageToExpense(imageFile, groupId, expenseId);
            setImageFile(null);
        }
    };

    const handleEditButtonClick = (e) => {
        e.stopPropagation(); // Prevent the expense selection
        const expenseData = {
            expenseId,
            name,
            originalAmount,
            convertedAmount,
            currency,
            originalCurrency,
            date,
            user,
            userImageUrl,
            imgLink,
            shares
        };
        if (!expenseId) {
            console.error('Expense ID is undefined!');
            return;
        }
        onEdit(expenseData); // Call the function passed from Main with the expense data
    };

    const handleImageClick = (e) => {
        e.stopPropagation(); // Prevent triggering onSelect when clicking the image
        setImageZoomed(!imageZoomed);
    };

    return (
        <div className='expense_cont'>
            {isSelected && imgLink && (
                <>
                    <img src={imgLink} alt="Expense" className="expense_img" onClick={handleImageClick} />
                    {imageZoomed && (
                        <div className="image_zoom_modal" onClick={() => setImageZoomed(false)}>
                            <img src={imgLink} alt="Zoomed Expense" style={{ maxWidth: '80%', maxHeight: '80%', display: 'block', margin: 'auto' }} />
                        </div>
                    )}
                </>
            )}
            <div className={`expense ${isSelected ? 'show-buttons' : ''}`} onClick={() => onSelect()}>
                {!userImageUrl ? (
                    <UilUserCircle size="60" /> // Adjust size as needed
                ) : (
                    <img src={userImageUrl} alt="User Avatar" className='user_img' style={{ borderRadius: '50%' }} />
                )}
                <div className='expense_details'>
                    <div style={{ width: '100%' }}>
                        <div className='expense_main'>
                            <div className='maybe'>
                                <div>
                                    <span className='Item_name'>{name}</span>
                                    <div className='item_details'>
                                        <span>{originalAmount}</span>
                                        <span className='item_currency'>{originalCurrency}</span>
                                        <span>|</span>
                                        <span>{date}</span>
                                    </div>
                                    <div className='item_details'>
                                        <span className='added_by'>by {user}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='maybe2'>
                                <span className='item_final_price'>{convertedAmount}</span>
                                <span className='item_currency'>{currency}</span>
                            </div>
                        </div>

                        {isSelected && (
                            <>
                                <div className='expense_shares'>
                                    <h4>Split</h4>
                                    {shares && shares.map((share, index) => (
                                        <div key={index} className='share_detail'>
                                            <span>{userNames[share.userId] || share.userId}: {share.share} {currency} ({((share.share / convertedAmount)*10).toFixed(0)}/10 )</span>
                                        </div>
                                    ))}
                                </div>

                                <div className='expense_buttons'>
                                    {/* {isCurrentUser && ( */}
                                        <>
                                            <button onClick={handleEditButtonClick}>
                                                <UilEditAlt />
                                            </button>
                                            <button onClick={handleDeleteExpense}>
                                                <UilTrashAlt />
                                            </button>
                                        </>
                                    {/* )} */}
                                    {/* <button onClick={handleAddCommentToExpense}>
                                        <UilComment />
                                    </button> */}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
